<template>
	<div class="sidebar-settings">
		<div class="top">
			<h1>{{ $t("general.menu") }}</h1>
			<div class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close /></div>
		</div>

		<div class="settings-content">
			<div class="row">
				<div class="item-menu dashboard" @click.stop="closeModal">
					<router-link :to="{ name: 'dashboard_user' }">
						<icon-dashboard class="icon-item" />
						<span class="text">{{ $t("navbar.dashboard") }}</span>
					</router-link>
				</div>
				<div class="item-menu users" @click.stop="closeModal">
					<router-link :to="{ name: 'users' }">
						<icon-people class="icon-item" />
						<span class="text">{{ $t("navbar.users") }}</span>
					</router-link>
				</div>
			</div>
			<!-- vartej -->
			<template>
				<div class="header">
					{{ $t("sidebar_tbf.vartej") }}
				</div>
				<div class="row wrap">
					<div class="item-menu" @click.stop="closeModal">
						<router-link :to="{ name: 'company' }">
							<icon-chart class="icon-item" />
							<span class="text">{{ $t("sidebar_tbf.organigram") }}</span>
						</router-link>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<router-link :to="{ name: 'vacant-jobs' }">
							<icon-vacant-job class="icon-item" />
							<span class="text">{{ $t("sidebar_tbf.vacant_jobs") }}</span>
						</router-link>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<router-link :to="{ name: 'roles' }">
							<icon-roles class="icon-item" />
							<span class="text">{{ $t("general.roles") }}</span>
						</router-link>
					</div>
					<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu" @click.stop="closeModal">
						<router-link :to="{ name: 'holidays' }">
							<icon-calendar class="icon-item" />
							<span class="text">{{ $t("settings-navbar.holidays") }}</span>
						</router-link>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<a :href="`${procedures_fe_link}/procedures`">
							<icon-procedures class="icon-item" />
							<span class="text">{{ $t("sidebar_tbf.procedures") }}</span>
						</a>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<a :href="`${procedures_fe_link}/processes`">
							<icon-processes class="icon-item" />
							<span class="text">{{ $t("sidebar_tbf.processes") }}</span>
						</a>
					</div>
				</div>
			</template>
			<!-- crestere -->
			<template>
				<div class="header">
					{{ $t("sidebar_tbf.crestere") }}
				</div>
				<div class="row wrap">
					<div class="item-menu" @click.stop="closeModal">
						<a :href="`${objectives_fe_link}/obiective`">
							<icon-objectives class="icon-item" />
							<span class="text">{{ $t("sidebar_tbf.objectives") }}</span>
						</a>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<a :href="`${objectives_fe_link}/master-goals`">
							<icon-master-goals class="icon-item" />
							<span class="text">{{ $t("sidebar_tbf.objectives-master-goal") }}</span>
						</a>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<a :href="`${objectives_fe_link}/smart-planner`">
							<icon-smart-planner class="icon-item" />
							<span class="text">{{ $t("sidebar_tbf.objectives-smart-planner") }}</span>
						</a>
					</div>
				</div>
			</template>
			<!-- rapoarte -->
			<template>
				<div class="header">
					{{ $t("navbar.reports") }}
				</div>
				<div class="row wrap">
					<div class="item-menu" @click.stop="closeModal">
						<router-link :to="{ name: 'reports' }">
							<icon-chart class="icon-item" />
							<span class="text">{{ $t("sidebar_tbf.responsibilities") }}</span>
						</router-link>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<a :href="`${procedures_fe_link}/reports?type=processes`">
							<icon-processes class="icon-item" />
							<span class="text">{{ $t("sidebar_tbf.processes") }}</span>
						</a>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<a :href="`${procedures_fe_link}/reports?type=procedures`">
							<icon-procedures class="icon-item" />
							<span class="text">{{ $t("sidebar_tbf.procedures") }}</span>
						</a>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<a :href="`${procedures_fe_link}/reports?type=improvements`">
							<icon-upgrade class="icon-item" />
							<span class="text">{{ $t("sidebar_tbf.process_update_requests") }}</span>
						</a>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<a :href="`${procedures_fe_link}/reports?type=users`">
							<icon-people class="icon-item" />
							<span class="text">{{ $t("navbar.users") }}</span>
						</a>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<a :href="`${objectives_fe_link}/rapoarte`">
							<icon-promise class="icon-item" />
							<span class="text">{{ $t("sidebar_tbf.promises") }}</span>
						</a>
					</div>
				</div>
			</template>
			<!-- setari -->
			<template>
				<div class="header">
					{{ $t("sidebar_tbf.settings") }}
				</div>
				<div class="row wrap">
					<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu" @click.stop="closeModal">
						<router-link :to="{ name: 'settings-work-schedules' }">
							<icon-work-schedule class="icon-item" />
							<span class="text">{{ $t("settings-navbar.work-schedules") }}</span>
						</router-link>
					</div>
					<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu" @click.stop="closeModal">
						<router-link :to="{ name: 'settings-departments' }">
							<icon-department class="icon-item" />
							<span class="text">{{ $t("settings-navbar.departments") }}</span>
						</router-link>
					</div>
					<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu" @click.stop="closeModal">
						<router-link :to="{ name: 'settings-workstations' }">
							<icon-building class="icon-item" />
							<span class="text">{{ $t("settings-navbar.workstations") }}</span>
						</router-link>
					</div>
					<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu" @click.stop="closeModal">
						<router-link :to="{ name: 'settings-skills' }">
							<icon-skill class="icon-item" />
							<span class="text">{{ $t("settings-navbar.skills") }}</span>
						</router-link>
					</div>
					<div class="item-menu" @click.stop="closeModal" v-if="['master_admin', 'admin'].includes(soft_procedures.auth_right) && soft_procedures.is_active">
						<a :href="`${procedures_fe_link}/settings/categories`">
							<icon-box-archive class="icon-item" />
							<span class="text">{{ $t("settings-navbar.categories") }}</span>
						</a>
					</div>
					<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu" @click.stop="closeModal">
						<router-link :to="{ name: 'settings-pipeline' }">
							<icon-pipeline class="icon-item" />
							<span class="text">{{ $t("settings-navbar.pipeline") }}</span>
						</router-link>
					</div>
					<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu" @click.stop="closeModal">
						<router-link :to="{ name: 'settings-company' }">
							<icon-about-company class="icon-item" />
							<span class="text">{{ $t("settings-navbar.company") }}</span>
						</router-link>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<router-link :to="{ name: 'settings-profile' }">
							<icon-user class="icon-item" />
							<span class="text">{{ $t("settings-navbar.profile") }}</span>
						</router-link>
					</div>
					<div class="item-menu" @click.stop="closeModal" v-if="$auth.check() && $auth.user().can_view_subscription">
						<router-link :to="{ name: 'settings-subscriptions' }">
							<icon-wallet class="icon-item" />
							<span class="text">{{ $t("settings-navbar.subscriptions") }}</span>
						</router-link>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script type="text/javascript">
import IconClose from "../Icons/Close";
import IconPeople from "../Icons/People";
import IconDashboard from "../Icons/Dashboard";
import IconChart from "../Icons/Chart";
import IconVacantJob from "../Icons/VacantJob";
import IconRoles from "../Icons/Tags";
import IconProcedures from "../Icons/Procedures";
import IconProcesses from "../Icons/Processes";
import IconObjectives from "../Icons/Objectives";
import IconMasterGoals from "../Icons/MasterGoals";
import IconSmartPlanner from "../Icons/SmartPlanner";
import IconUpgrade from "../Icons/Upgrade";
import IconPromise from "../Icons/Promise";
import IconWorkSchedule from "../Icons/WorkSchedule";
import IconDepartment from "../Icons/Department";
import IconBuilding from "../Icons/Building";
import IconSkill from "../Icons/Skill";
import IconCalendar from "../Icons/Calendar";
import IconBoxArchive from "../Icons/BoxArchive";
import IconAboutCompany from "../Icons/AboutCompany";
import IconUser from "../Icons/User";
import IconGears from "../Icons/Gears";
import IconWallet from "../Icons/Wallet";
import IconPipeline from "../Icons/IconPipeline";

export default {
	data() {
		return {
			objectives_fe_link: process.env.VUE_APP_OBJECTIVE_FE_LINK,
			procedures_fe_link: process.env.VUE_APP_PROCEDURES_FE_LINK,
		};
	},
	props: {},
	components: {
		IconClose,
		IconPeople,
		IconDashboard,
		IconChart,
		IconVacantJob,
		IconRoles,
		IconProcedures,
		IconProcesses,
		IconObjectives,
		IconMasterGoals,
		IconSmartPlanner,
		IconUpgrade,
		IconPromise,
		IconWorkSchedule,
		IconDepartment,
		IconBuilding,
		IconSkill,
		IconCalendar,
		IconBoxArchive,
		IconAboutCompany,
		IconUser,
		IconGears,
		IconWallet,
		IconPipeline,
	},
	async mounted() {},
	computed: {
		soft_procedures() {
			return this.$store.getters["applications/getApplication"]("Proceduri");
		},
		soft_objectives() {
			return this.$store.getters["applications/getApplication"]("Obiective");
		},
		soft_organigram() {
			return this.$store.getters["applications/getApplication"]("Organigrama");
		},
	},
	methods: {
		closeModal() {
			this.$emit("close_modal");
		},
	},
};
</script>
