<template>
	<div class="modal_info choose-save-mode">
		<div class="header-submodal">
			<div class="title">{{ data.type == "meeting" ? $t("add_meeting.choose_save_mode") : $t("add_task.choose_save_mode") }}</div>
		</div>

		<div class="form-section">
			<div class="input-group w-100" v-for="optionSave in options">
				<label class="checkbox-tbf type-radio">
					<input type="radio" :value="optionSave" v-model="optionSelected" />
					<span class="checkmark"></span>
					<span class="text-checkbox">{{
						data.type == "meeting" ? $t(`add_meeting.${optionSave}_mode`) : $t(`add_task.${optionSave}_mode`)
					}}</span>
				</label>
			</div>
		</div>

		<div class="atention-modified-frequency" v-if="data.objData.modified_frequency">
			{{ $t("validator.attention_modified_frequency") }}
		</div>

		<div v-if="$v.optionSelected.$error" class="error-msg">{{ $t("validator.required_save_mode") }}</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelClose">
				<div class="text">{{ $t("general.cancel") }}</div>
			</button>
			<button id="submitSave" class="btn-tbf blue center" @click="submitAction">
				<div class="loader-spin" v-if="loadingSave"></div>
				<div class="text" v-else>{{ $t("general.update") }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	import { required } from "vuelidate/lib/validators";

	export default {
		props: {
			options: Array,
			data: Object,
		},
		data() {
			return {
				loadingSave: false,
				optionSelected: "",
			};
		},
		validations: {
			optionSelected: { required },
		},
		async mounted() {},
		methods: {
			cancelClose() {
				this.$emit("cancel_close");
			},
			submitAction() {
				var buttonSubmit = document.getElementById("submitSave");
				this.loadingSave = true;
				buttonSubmit.disabled = true;

				this.$v.$touch();

				if (!this.$v.$invalid) {
					switch (this.optionSelected) {
						case "current":
							this.data.paramsQuery.current = 1;
							break;
						case "current_and_future":
							this.data.paramsQuery.current_and_future = 1;
							this.data.objData.date = this.data.activityDate ? this.data.activityDate : this.data.objData.date;
							break;
						case "all":
							this.data.paramsQuery.all = 1;
							break;
					}

					if (this.data.type == "task") {
						this.$parent.updateTask(this.data.objData, this.data.paramsQuery, this.data.withComplete);
					} else if (this.data.type == "meeting") {
						this.$parent.updateMeeting(this.data.objData, this.data.paramsQuery);
					}
				} else {
					this.loadingSave = false;
					buttonSubmit.disabled = false;
				}
			},
		},
	};
</script>
