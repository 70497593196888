<template>
	<div class="modal-tbf modal_share">
		<div class="container-modal form-modal">
			<div class="header-modal">
				<div class="title">{{ $t("share_tree_chart.title") }}</div>
				<div class="actions" @click="closeModal">
					<div class="btn-tbf white only-icon close-btn"><icon-close /></div>
				</div>
			</div>

			<div class="input-group-tbf">
				<div class="label-input">{{ $t("share_tree_chart.label-status") }}</div>
				<div class="btns-status">
					<button class="button-action btn-default-tbf" @click="changeStatus(true)" v-bind:class="{ active: share == true }">
						<div class="loader-spin" v-if="loadingSubmit"></div>
						<div v-else>{{ $t("share_tree_chart.start-share") }}</div>
					</button>
					<button class="button-action btn-default-tbf" @click="changeStatus(false)" v-bind:class="{ inactive: share == false }">
						{{ $t("share_tree_chart.stop-share") }}
					</button>
				</div>
			</div>
			<template v-if="share == true">
				<div class="input-group-tbf">
					<div class="label-input">{{ $t("share_tree_chart.label-link") }}</div>
					<div class="input-box referral-copy">
						<div class="icon-left"><icon-referral-code /></div>
						<div class="icon-right" @click="copyToClipboard('referralCode')">{{ $t("share_tree_chart.btn-copy") }}</div>
						<input type="text" id="referralCode" class="input-text" :value="link" readonly />
					</div>
				</div>
				<div class="input-group-tbf m-0">
					<div class="label-input">{{ $t("share_tree_chart.embed") }}</div>
					<div class="input-box embed-copy">
						<div class="icon-left"><icon-code /></div>
						<div class="icon-right" @click="copyToClipboard('embedCode')">{{ $t("share_tree_chart.btn-copy") }}</div>
						<textarea type="text" id="embedCode" class="input-text" :value="embed" readonly></textarea>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script type="text/javascript">
import IconClose from "../Icons/Close";
import IconInfo from "../Icons/Info";
import IconReferralCode from "../Icons/ReferralCode";
import IconCode from "../Icons/Code";

export default {
	data() {
		return {
			loadingSubmit: false,
			share: this.$auth.user().instance.share_uuid ? true : false,
			link: process.env.VUE_APP_URL_FE + "/public/" + this.$auth.user().instance.share_uuid,
			embed: "",
		};
	},
	props: {
		model: String,
	},
	components: {
		IconClose,
		IconReferralCode,
		IconInfo,
		IconCode,
	},
	async mounted() {
		if (this.share) {
			this.embed = `<iframe src="${this.link}" title="${this.$auth.user().instance.company_name}" width="500" height="300"/>`;
		}
	},
	methods: {
		closeModal() {
			this.$emit("close_modal");
		},
		changeStatus(status) {
			$(".btns-status .button-action").attr("disabled", "disabled");
			if (status == true) {
				this.loadingSubmit = true;
			} else {
				this.share = false;
			}

			axios
				.post("/instances/share-link", { new_link: status })
				.then(({ data }) => {
					$(".btns-status .button-action").attr("disabled", false);
					this.share = status;
					this.link = process.env.VUE_APP_URL_FE + "/public/" + data.data;
					this.embed = `<iframe src="${this.link}" title="${this.$auth.user().instance.company_name}" width="500" height="300"/>`;
				})
				.finally(() => {
					this.$auth.fetch();
					this.loadingSubmit = false;
				});
		},
		copyToClipboard(type) {
			var copyText = document.getElementById(type);
			copyText.select();
			copyText.setSelectionRange(0, 99999);
			document.execCommand("copy");

			if (type == "referralCode") {
				$(".referral-copy .icon-right").addClass("success");
				$(".referral-copy .icon-right").html(this.$t("share_tree_chart.btn-copied"));
				setTimeout(() => {
					$(".referral-copy .icon-right").removeClass("success");
					$(".referral-copy .icon-right").html(this.$t("share_tree_chart.btn-copy"));
				}, 3000);
			} else {
				$(".embed-copy .icon-right").addClass("success");
				$(".embed-copy .icon-right").html(this.$t("share_tree_chart.btn-copied"));
				setTimeout(() => {
					$(".embed-copy .icon-right").removeClass("success");
					$(".embed-copy .icon-right").html(this.$t("share_tree_chart.btn-copy"));
				}, 3000);
			}
		},
	},
};
</script>
