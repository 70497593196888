<template>
	<div class="sidebar-settings-tbf">
		<div class="header">{{ $t("navbar.settings") }}</div>
		<div class="main-menu">
			<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu">
				<router-link :to="{ name: 'settings-work-schedules' }">
					<span class="text">{{ $t("settings-navbar.work-schedules") }}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
			<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu">
				<router-link :to="{ name: 'settings-departments' }">
					<span class="text">{{ $t("settings-navbar.departments") }}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
			<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu">
				<router-link :to="{ name: 'settings-workstations' }">
					<span class="text">{{ $t("settings-navbar.workstations") }}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
			<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu">
				<router-link :to="{ name: 'settings-skills' }">
					<span class="text">{{ $t("settings-navbar.skills") }}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
			<div v-if="['master_admin', 'admin'].includes(soft_procedures.auth_right) && soft_procedures.is_active" class="item-menu">
				<a :href="`${procedures_fe_link}/settings/categories`">
					<span class="text">{{ $t("settings-navbar.categories") }}</span>
					<div class="arrow"><icon-arrow /></div>
				</a>
			</div>
			<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu">
				<router-link :to="{ name: 'settings-pipeline' }">
					<span class="text">{{ $t("settings-navbar.pipeline") }}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
			<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu">
				<router-link :to="{ name: 'settings-company' }">
					<span class="text">{{ $t("settings-navbar.company") }}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
			<div class="item-menu">
				<router-link :to="{ name: 'settings-profile' }">
					<span class="text">{{ $t("settings-navbar.profile") }}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
			<div class="item-menu" v-if="$auth.user().rights.can_view_subscription">
				<router-link :to="{ name: 'settings-subscriptions' }">
					<span class="text">{{ $t("settings-navbar.subscriptions") }}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import IconArrow from "../Icons/Arrow.vue";

export default {
	components: {
		IconArrow,
	},
	data() {
		return {
			procedures_fe_link: process.env.VUE_APP_PROCEDURES_FE_LINK,
		};
	},
	methods: {},
	computed: {
		soft_procedures() {
			return this.$store.getters["applications/getApplication"]("Proceduri");
		},
		soft_objectives() {
			return this.$store.getters["applications/getApplication"]("Obiective");
		},
		soft_organigram() {
			return this.$store.getters["applications/getApplication"]("Organigrama");
		},
	},
};
</script>

<style lang="scss">
@import "../../styles/templates/settings-template.scss";
</style>
