<template>
	<div class="modal_info modal_show_evaluation">
		<template v-if="loaded">
			<div class="header-submodal">
				<div class="title">
					{{
						$t("show_user.evaluation.title", {
							name: `${data.user.first_name} ${data.user.last_name ? data.user.last_name : ""}`,
						})
					}}
				</div>
			</div>
			<div class="subheader-data">
				<div class="item">{{ $t("general.date") }}: {{ data.created_at | moment("DD MMMM YYYY") }}</div>
				<div class="item">
					{{ $t("show_user.evaluation.rating") }}:
					<star-rating v-model="data.stars" :show-rating="false" :star-size="15" :increment="0.5" :read-only="true" />
				</div>
				<div class="item">
					{{ $t("show_user.evaluation.evaluator") }}:
					{{ `${data.evaluator.first_name} ${data.evaluator.last_name ? data.evaluator.last_name : ""}` }}
				</div>
			</div>

			<div class="description-modal">
				<div class="label">{{ $t("show_user.evaluation.good_things") }}</div>
				<div class="value">{{ data.good_things }}</div>
			</div>
			<div class="description-modal">
				<div class="label">{{ $t("show_user.evaluation.what_to_change") }}</div>
				<div class="value">{{ data.what_to_change }}</div>
			</div>

			<div class="description-modal" v-if="data.files && data.files.length">
				<div class="label">{{ $t("show_user.evaluation.files") }}</div>
				<template v-for="file in data.files">
					<a class="files" target="_blank" :href="file.file_path">{{ file.filename }}</a>
					<br />
				</template>
			</div>

			<div class="data-list" v-if="responsibilities.length">
				<div class="title">
					{{ $t("user-details.responsibilities") }}
				</div>
				<div class="row-space-tbf header-list">
					<div class="content">
						<div class="column-filter column-user">
							<div class="text">{{ $t("general.name") }}</div>
						</div>
						<div v-if="$resize && $mq.above(700)" class="column-filter column-total-sum">
							<div class="text">{{ $t("report_users.total_responsibilities") }}</div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-total-sum">
							<div class="text">{{ $t("report_users.total_target") }}</div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-total-sum">
							<div class="text">{{ $t("report_users.total_unrealized") }}</div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-total-sum">
							<div class="text">{{ $t("report_users.total_unrealized_special_reasons") }}</div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-total-sum">
							<div class="text">{{ $t("report_users.total_accomplished") }}</div>
						</div>
					</div>
				</div>

				<div class="scrollable">
					<template v-for="entry in responsibilities">
						<div class="row-space-tbf row-list-item" :key="`entryItem${entry.id}`">
							<div class="content">
								<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
								<div class="column-user" :class="[entry.progress_type ? 'column-name-desc' : 'column-name-title']">
									<div class="text">
										<v-popover
											offset="5"
											trigger="hover"
											placement="bottom"
											popoverBaseClass="popover-tbf"
											:delay="{ show: 200, hide: 0 }"
										>
											<div class="name">{{ entry.name }}</div>
											<template slot="popover">
												<div class="simple-text">{{ entry.name }}</div>
											</template>
										</v-popover>
										<div class="description" v-if="entry.progress_type">
											{{
												$t("responsibility.progress_type_text." + entry.progress_type, {
													to: parseFloat(entry.target),
													unit: entry.unity,
												})
											}}
										</div>
									</div>
								</div>
								<div v-if="$resize && $mq.above(700)" class="column-simple-text column-total-sum">
									<div class="text">{{ parseFloat(parseFloat(entry.total_logs).toFixed(2)) }}</div>
								</div>
								<div v-if="$resize && $mq.above(501)" class="column-simple-text column-total-sum">
									<div class="text">{{ parseFloat(parseFloat(entry.total_target).toFixed(2)) }}</div>
								</div>
								<div v-if="$resize && $mq.above(501)" class="column-simple-text column-total-sum">
									<div class="text">{{ parseFloat(parseFloat(entry.total_logs_unrealized).toFixed(2)) }}</div>
								</div>
								<div v-if="$resize && $mq.above(501)" class="column-simple-text column-total-sum">
									<div class="text">{{ parseFloat(parseFloat(entry.total_logs_special_reason).toFixed(2)) }}</div>
								</div>
								<div
									v-if="$resize && $mq.above(501)"
									class="column-simple-text column-total-sum"
									:class="[checkClassProgress(entry)]"
								>
									<div class="text">
										{{
											entry.total_value_logs == "no_evaluations"
												? $t("report_users.no_evaluations")
												: parseFloat(parseFloat(entry.total_value_logs).toFixed(2))
										}}
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>

			<div class="button-actions">
				<button class="btn-tbf center blue" @click="closeModal">
					<div class="text">{{ $t("general.close") }}</div>
				</button>
			</div>
		</template>
	</div>
</template>

<script type="text/javascript">
import IconClose from "../Icons/Close";
import StarRating from "vue-star-rating";

export default {
	data() {
		return {
			loaded: false,
			responsibilities: [],
		};
	},
	props: {
		data: Object,
	},
	components: {
		IconClose,
		StarRating,
	},
	mounted() {
		setTimeout(() => {
			this.getResponsibilities();
		}, 200);
	},
	methods: {
		closeModal() {
			this.$emit("close_modal");
		},
		getResponsibilities() {
			var paramsCall = {};
			paramsCall.start_date = this.data.previous_evaluation_date;
			paramsCall.end_date = moment(this.data.created_at).format("YYYY-MM-DD");

			axios
				.get(`/reports/${this.data.user.slug}/user-responsibilities`, { params: paramsCall })
				.then(({ data }) => {
					this.responsibilities = data.data;
				})
				.finally(() => {
					this.loaded = true;
				});
		},
		checkClassProgress(data) {
			if (data.type == "check") {
				if (data.total_value_logs >= data.total_target) {
					return "green";
				}
			} else if (data.type == "value") {
				if (data.progress_type == "grow") {
					if (data.total_value_logs >= data.total_target) {
						return "green";
					}
				} else {
					if (data.total_value_logs != "no_evaluations") {
						if (data.total_value_logs <= data.total_target) {
							return "green";
						}
					} else {
						return "red";
					}
				}
			}

			return "red";
		},
	},
};
</script>
