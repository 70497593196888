<template>
	<div class="modal_info modal_create_link">
		<div class="form-section mt-0">
			<div class="input-group w-100">
				<div class="label-header">
					<label class="label">{{ $t("projects.modal_link.insert_url") }}</label>
					<div v-if="$v.linkUrl.$error" class="error-msg">{{ $t("validator.required") }}</div>
				</div>
				<div class="input-box bg-white department" v-bind:class="{ has_error: $v.linkUrl.$error }">
					<div class="icon-left"><icon-link /></div>
					<multiselect
						v-model="linkUrl"
						id="ajax"
						label="name"
						:placeholder="$t('projects.modal_link.input_placeholder')"
						open-direction="bottom"
						:options="optionsFound"
						:multiple="false"
						:searchable="true"
						:loading="isLoading"
						:internal-search="false"
						:close-on-select="true"
						:show-labels="false"
						:options-limit="300"
						:limit="3"
						:limit-text="limitText"
						:max-height="120"
						:show-no-results="true"
						@search-change="debouncedSearchAsync"
					>
						<template slot="option" slot-scope="props">
							<div class="option-link">
								<div class="icon">
									<icon-web-globe v-if="props.option.type == 'link'" />
									<icon-procedure v-else-if="props.option.type == 'procedure'" />
									<icon-process v-else-if="props.option.type == 'process'" />
									<icon-project v-else-if="props.option.type == 'project'" />
									<icon-objective v-else-if="props.option.type == 'objective'" />
								</div>
								<div class="data">
									<div class="name" v-html="props.option.name"></div>
									<div class="description">{{ $t(`projects.modal_link.type.${props.option.type}`) }}</div>
								</div>
							</div>
						</template>
						<template slot="clear" slot-scope="props">
							<div class="multiselect__clear" v-if="linkUrl" @mousedown.prevent.stop="clearAll(props.search)"></div>
						</template>
						<span slot="noResult">{{ $t("projects.modal_link.search_error") }}</span>
					</multiselect>
				</div>
			</div>
		</div>

		<div class="button-actions">
			<button class="btn-tbf center grey" @click="closeModal">
				<div class="text">{{ $t("general.close") }}</div>
			</button>

			<button class="btn-tbf center blue" @click="convertToLink()">
				<div class="text">{{ $t("general.save") }}</div>
			</button>
		</div>
	</div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import IconLink from "@/components/Icons/ReferralCode";
import IconWebGlobe from "@/components/Icons/WebGlobe";
import IconProcedure from "@/components/Icons/Procedures";
import IconProcess from "@/components/Icons/Processes";
import IconProject from "@/components/Icons/Projects";
import IconObjective from "@/components/Icons/Objectives";
import { debounce } from "debounce";

export default {
	components: {
		IconLink,
		IconWebGlobe,
		IconProcedure,
		IconProcess,
		IconProject,
		IconObjective,
	},
	data() {
		return {
			linkUrl: "",
			optionsFound: [],
			isLoading: false,
		};
	},
	props: {
		data: Object,
	},
	validations: {
		linkUrl: { required },
	},
	created() {
		this.debouncedSearchAsync = debounce((data) => {
			this.checkTask(data);
		}, 500);
	},
	mounted() {},
	methods: {
		limitText(count) {
			return `and ${count} other countries`;
		},
		clearAll() {
			this.linkUrl = "";
		},
		async checkTask(query) {
			this.isLoading = true;
			this.optionsFound = [];

			if (this.validateUrl(query)) {
				if (!this.optionsFound.find((el) => el.link == query)) {
					this.optionsFound.push({ link: query, name: query, type: "link" });
				}
				this.isLoading = false;
			} else {
				var objectivesReady = false;
				var proceduresReady = false;
				var organigramaReady = false;
				// Get Objectives
				axios
					.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/instances/${this.$auth.user().master_instance_id}/filter/external`, { params: { objectives: true, search_by: query } })
					.then(({ data }) => {
						var mergeObjectivesArray = [...this.optionsFound, ...data.data.objectives];
						this.optionsFound = mergeObjectivesArray;
					})
					.finally(() => {
						objectivesReady = true;
					});
				// Get procedures and processes
				axios
					.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/instances/${this.$auth.user().master_instance_id}/filter/external`, {
						params: { procedures: true, processes: true, search_by: query },
					})
					.then(({ data }) => {
						var mergeProceduresArray = [...this.optionsFound, ...data.data.procedures, ...data.data.processes];
						this.optionsFound = mergeProceduresArray;
					})
					.finally(() => {
						proceduresReady = true;
					});

				// Get projects
				axios
					.get(`/instances/${this.$auth.user().master_instance_id}/filter/external`, {
						params: { projects: true, search_by: query },
					})
					.then(({ data }) => {
						var mergeProjectsArray = [...this.optionsFound, ...data.data.projects];
						this.optionsFound = mergeProjectsArray;
					})
					.finally(() => {
						organigramaReady = true;
					});

				var checkReadyArray = setInterval(() => {
					if (objectivesReady && proceduresReady && organigramaReady) {
						this.isLoading = false;
						clearInterval(checkReadyArray);
					}
				}, 50);
			}
		},
		validateUrl(value) {
			return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(value);
		},
		closeModal() {
			this.$emit("close_modal");
		},
		convertToLink() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				var linkUrlFormated = "";

				switch (this.linkUrl.type) {
					case "objective":
						//https://obiective.tbf.ro/obiectiv/obiectiv-de-test-3
						linkUrlFormated = `${process.env.VUE_APP_OBJECTIVE_FE_LINK}/obiectiv/${this.linkUrl.slug}`;
						break;
					case "procedure":
						//https://proceduri.tbf.ro/procedure/procedura-adaugare-client
						linkUrlFormated = `${process.env.VUE_APP_PROCEDURES_FE_LINK}/procedure/${this.linkUrl.slug}`;
						break;
					case "process":
						//https://proceduri.tbf.ro/process/proces-nou-2
						linkUrlFormated = `${process.env.VUE_APP_PROCEDURES_FE_LINK}/process/${this.linkUrl.slug}`;
						break;
					case "project":
						//https://organigrama.tbf.ro/projects/maximus-odio-enim-vestibulum-pulvinar-viverra-ultrices-felis-tellus-lobortis
						linkUrlFormated = `${process.env.VUE_APP_ORGANIGRAM_FE_LINK}/projects/${this.linkUrl.slug}`;
						break;
					case "link":
						linkUrlFormated = this.linkUrl.link;
						break;
				}

				this.$root.$emit("convert_text_to_link", { linkUrl: linkUrlFormated, selectionRange: this.data.selectionRange, blockId: this.data.blockId });
				this.closeModal();
			}
		},
	},
};
</script>
