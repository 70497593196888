<template>
	<div class="modal_info modal_evaluate_responsibility">
		<template v-if="loaded">
			<div class="header-submodal">
				<div class="title">{{ responsibility.name }}</div>
			</div>

			<div class="subheader-data">
				<div class="item" v-if="data.date">{{ $t("general.date") }}: {{ data.date | moment("DD MMMM YYYY") }}</div>
				<div class="item">{{ $t("roles.role") }}: {{ responsibility.role_name }}</div>
			</div>

			<div class="description-modal" v-html="responsibility.description" v-if="responsibility.description"></div>

			<div class="trigger" v-if="responsibility.trigger">
				<div class="title">{{ $t("general.trigger") }}</div>
				<div class="description">{{ responsibility.trigger }}</div>
			</div>

			<div class="linked-procedures" v-if="responsibility.procedures && responsibility.procedures.length && loadedProcedures">
				<div class="title-section">{{ $t("responsibility.connected_procedures") }}</div>
				<div
					class="procedure-run-block"
					v-for="procedure_run in responsibility.procedures"
					:set="(procedure_data = proceduresTracking.filter((el) => el.procedure.id == procedure_run.id))"
					:class="classStatusProcedureRun(procedure_run.id)"
				>
					<template v-if="procedure_data.find((el) => el.status == 'closed' && el.completed == 1)">
						<div class="name">{{ procedure_run.name }}</div>
						<div class="tracking">
							<div class="icon"><icon-check /></div>
						</div>
					</template>
					<template v-else-if="procedure_data.find((el) => el.status == 'open')">
						<div class="name" @click="showModalProcedureCheck(procedure_run.id)">{{ procedure_run.name }}</div>
						<div class="tracking">
							<span class="percentage" :set="(data_procedure_find = procedure_data.find((el) => el.status == 'open'))">
								{{ `${data_procedure_find.checked_steps.length}/${data_procedure_find.total_steps}` }}
							</span>
						</div>
					</template>
					<template v-else>
						<div class="name" @click="startProcedureRun(procedure_run.id)">{{ procedure_run.name }}</div>
						<div class="tracking">
							<div class="icon linked" @click="startProcedureRun(procedure_run.id)">
								<icon-play-circle />
							</div>
						</div>
					</template>
				</div>
			</div>

			<div class="target" v-if="responsibility.type == 'value'">
				<div class="label">{{ $t("responsibility.target_responsibility") }}:</div>
				<div class="value">
					{{
						$t("responsibility.progress_type_text." + responsibility.progress_type, {
							to: parseFloat(responsibility.target),
							unit: responsibility.unity,
						})
					}}
				</div>
			</div>

			<div class="form-section" v-if="responsibility.type && data.from == 'user'">
				<div
					class="input-group w-100"
					v-if="responsibility.type == 'value'"
					:class="{ disabled: free_day || responsibility.is_deleted || isDisabled }"
				>
					<div class="label-header">
						<label class="label">{{ $t("responsibility.value") }}</label>
						<div v-if="$v.value.$error" class="error-msg">
							{{ !$v.value.decimal ? $t("validator.required_decimal") : $t("validator.required") }}
						</div>
					</div>
					<div class="input-box bg-white progress-input" v-bind:class="{ has_error: $v.value.$error }">
						<div class="progress-left">
							<icon-progress />
							<div
								class="value"
								:class="{ positive: checkPositiveClass(), negative: checkNegativeClass() }"
								v-if="valueDebounce !== ''"
							>
								<span
									class="plus"
									v-if="
										parseFloat(
											parseFloat(
												parseFloat(valueDebounce).toFixed(2) - parseFloat(responsibility.target).toFixed(2)
											).toFixed(2)
										) > 0
									"
									>+</span
								>{{
									parseFloat(
										parseFloat(
											parseFloat(valueDebounce).toFixed(2) - parseFloat(responsibility.target).toFixed(2)
										).toFixed(2)
									)
								}}
							</div>
						</div>
						<input
							type="number"
							:placeholder="$t('responsibility.value_placeholder')"
							class="input-text no-icon"
							v-model="value"
							v-debounce:300="updateValue"
							:disabled="free_day || responsibility.is_deleted || isDisabled"
						/>
					</div>
				</div>

				<div
					class="input-group w-100 checkboxes-group"
					v-if="responsibility.type == 'check'"
					:class="{ disabled: free_day || responsibility.is_deleted || isDisabled }"
				>
					<div class="box-checkbox" v-bind:class="{ has_error: $v.complete.$error, selected: complete === 0 }">
						<label class="checkbox-tbf">
							<input
								type="radio"
								:value="0"
								v-model="complete"
								:disabled="free_day || responsibility.is_deleted || isDisabled"
							/>
							<img src="/build/images/mood-uncertain-bw.svg" class="inactive" />
							<img src="/build/images/mood-trs-uncertain.svg" class="active" />
							<span class="text-checkbox">{{ $t("responsibility.unfinished") }}</span>
						</label>
					</div>
					<div class="box-checkbox" v-bind:class="{ has_error: $v.complete.$error, selected: complete === 1 }">
						<label class="checkbox-tbf">
							<input
								type="radio"
								:value="1"
								v-model="complete"
								:disabled="free_day || responsibility.is_deleted || isDisabled"
							/>
							<img src="/build/images/mood-sure-bw.svg" class="inactive" />
							<img src="/build/images/mood-trs-sure.svg" class="active" />
							<span class="text-checkbox">{{ $t("responsibility.finished") }}</span>
						</label>
					</div>
				</div>

				<!-- upload file  -->
				<div
					class="input-group w-100 mr-top"
					v-if="responsibility.upload_file && (complete === 1 || checkPositiveClass())"
					:class="{ disabled: free_day || responsibility.is_deleted || isDisabled }"
				>
					<div class="label-header">
						<label class="label">{{ $t("responsibility.requires_proof_checkbox") }}</label>
						<div v-if="$v.hasFile.$error" class="error-msg">{{ $t("validator.required") }}</div>
					</div>
					<div class="w-flex">
						<div
							class="input-box pad-10"
							v-model="hasFile"
							onclick="document.getElementById('fileUploaded').click();"
							v-bind:class="{ has_error: $v.$error }"
						>
							<icon-upload class="icon-upload" />
							<label class="placeholder" v-if="!fileUpload">{{ $t("create-user.add-file") }}</label>
							<div class="text" v-else>{{ fileUpload ? fileUpload.name : "" }}</div>
							<input
								type="file"
								id="fileUploaded"
								style="display:none"
								v-on:change="onFileChange"
								:disabled="free_day || responsibility.is_deleted || isDisabled"
							/>
						</div>
					</div>

					<div v-if="fileError" class="error-msg custom-file-error">{{ fileError }}</div>

					<div class="selected-files">
						<div v-for="(file, index) in savedFiles" class="file-item">
							<span class="name">
								<icon-file class="icon-file" />
								<a :href="file.file_path" target="_blank" v-if="file.file_path">{{ file.filename }} </a>
								<div v-else>{{ file.filename }}</div>
								<button class="remove" @click="deleteFile(file, index)"><icon-close /></button>
							</span>
						</div>
					</div>
				</div>

				<div class="input-group w-100 mr-top" :class="{ disabled: responsibility.is_deleted || isDisabled }">
					<div class="label-header">
						<label class="label">{{ $t("responsibility.description") }}</label>
						<div v-if="$v.description.$error" class="error-msg">{{ $t("validator.required") }}</div>
					</div>
					<div class="input-box" v-bind:class="{ has_error: $v.description.$error }">
						<textarea v-model="description" :disabled="responsibility.is_deleted || isDisabled"></textarea>
					</div>
				</div>

				<div
					class="input-group w-100 mr-top free-day"
					v-if="complete === 0"
					:class="{ disabled: responsibility.is_deleted || isDisabled }"
				>
					<label class="checkbox-tbf">
						<input type="checkbox" value="1" v-model="free_day" :disabled="responsibility.is_deleted || isDisabled" />
						<span class="checkmark"></span>
						<span class="text-checkbox">{{ $t("responsibility.free_day") }}</span>
					</label>
				</div>

				<template v-if="data.userRight && data.userId != $auth.user().id">
					<div class="input-group w-100 mr-top">
						<div class="buttons-warnings-congratulations">
							<button
								class="button congratulations"
								@click="
									showTextarea == 'congratulations' ? (showTextarea = '') : (showTextarea = 'congratulations');
									description_review = '';
								"
							>
								<div v-if="showTextarea != 'congratulations'">{{ $t("responsibility.congratulations") }}</div>
								<div v-if="showTextarea == 'congratulations'" class="cancel">{{ $t("responsibility.cancel") }}</div>
							</button>
							<button
								class="button warnings"
								@click="
									showTextarea == 'warnings' ? (showTextarea = '') : (showTextarea = 'warnings');
									description_review = '';
								"
							>
								<div v-if="showTextarea != 'warnings'">{{ $t("responsibility.warning") }}</div>
								<div v-if="showTextarea == 'warnings'" class="cancel">{{ $t("responsibility.cancel") }}</div>
							</button>
						</div>
					</div>

					<div class="textarea-warnings-congratulations input-group w-100" v-if="showTextarea != ''">
						<div class="label-header">
							<label class="label">{{
								showTextarea == "congratulations"
									? $t("responsibility.send_congratulation")
									: $t("responsibility.send_warning")
							}}</label>
							<div v-if="$v.description_review.$error || error_description_review" class="error-msg">
								{{ $t("validator.required") }}
							</div>
						</div>
						<div class="input-box" v-bind:class="{ has_error: $v.description_review.$error || error_description_review }">
							<textarea v-model="description_review"></textarea>
						</div>
					</div>
				</template>
			</div>

			<template v-if="responsibility.is_deleted || isDisabled || needCompleteResponsibilityWithProcedures">
				<div class="msg-error-is-deleted" v-if="responsibility.is_deleted">{{ $t("responsibility.is_deleted") }}</div>
				<div class="msg-error-is-deleted" v-else-if="isDisabled">{{ $t("responsibility.is_disabled") }}</div>
				<div class="msg-error-is-deleted" v-else-if="needCompleteResponsibilityWithProcedures">
					{{ $t("responsibility.required_run_procedures") }}
				</div>
			</template>

			<div class="button-actions">
				<button class="btn-tbf center" :class="[data.from == 'user' ? 'grey' : 'blue']" @click="closeModal">
					<div class="text">{{ $t("general.close") }}</div>
				</button>
				<template v-if="responsibility.frequency">
					<button
						id="buttonUpdate"
						class="btn-tbf blue center"
						:class="{ disabled: needCompleteResponsibilityWithProcedures }"
						@click="!responsibility.user_responsibility_log ? saveUpdates('create') : saveUpdates('update')"
						v-if="data.from == 'user' && !responsibility.is_deleted && !isDisabled"
						:disabled="needCompleteResponsibilityWithProcedures"
					>
						<div class="loader"></div>
						<div class="text">{{ $t("general.update") }}</div>
					</button>
				</template>
			</div>
		</template>
		<loader-show-responsibility v-else />
	</div>
</template>

<script>
import { required, requiredIf, decimal } from "vuelidate/lib/validators";
import IconProgress from "../Icons/Progress";
import IconClose from "../Icons/Close";
import LoaderShowResponsibility from "@/components/PagesLoaders/ShowResponsibility";
import IconUpload from "../Icons/Upload";
import IconFile from "../Icons/File";
import IconEdit from "../Icons/Edit";
import IconCheck from "../Icons/CheckLight";
import IconPlayCircle from "../Icons/LearnProcedures";

export default {
	data() {
		return {
			loaded: false,
			loadedProcedures: false,
			value: "",
			valueDebounce: "",
			complete: "",
			description: "",
			free_day: false,
			responsibility: {},
			showTextarea: "",
			description_review: "",
			error_description_review: false,
			requiresProof: false,
			fileUpload: "",
			savedFiles: [],
			fileError: "",
			hasFile: "",
			awaitingFilesForRemove: [],
			isDisabled: false,
			proceduresTracking: [],
			needCompleteResponsibilityWithProcedures: false,
		};
	},
	props: {
		data: Object,
	},
	components: {
		IconProgress,
		IconClose,
		LoaderShowResponsibility,
		IconUpload,
		IconEdit,
		IconFile,
		IconCheck,
		IconPlayCircle,
	},
	validations: {
		description: {
			required: requiredIf(function() {
				return this.checkShowWhyNot();
			}),
		},
		value: {
			required: requiredIf(function() {
				return this.responsibility.type == "value" && !this.free_day;
			}),
			decimal,
		},
		complete: {
			required: requiredIf(function() {
				return this.responsibility.type == "check" && !this.free_day;
			}),
		},
		description_review: {
			required: requiredIf(function() {
				return this.showTextarea != "";
			}),
		},
		hasFile: {
			required: requiredIf(function() {
				return (this.complete == 1 || this.checkPositiveClass()) && this.responsibility.upload_file;
			}),
		},
	},
	mounted() {
		this.getDataResponsibility();
	},
	methods: {
		getDataResponsibility() {
			var paramsHeader = {};

			if (this.data.from == "user") {
				paramsHeader.user_id = this.data.userId;
				paramsHeader.date = this.data.date;
			}

			axios.get(`/role-responsibilities/${this.data.id}/show`, { params: paramsHeader }).then(({ data }) => {
				this.responsibility = data.data;
				this.isDisabled = !data.data.evaluate_past_responsibility;

				if (this.responsibility.user_responsibility_log) {
					this.free_day = this.responsibility.user_responsibility_log.free_day ? true : false;
					this.complete = this.responsibility.user_responsibility_log.completed;
					this.value = this.responsibility.user_responsibility_log.evaluated
						? parseFloat(parseFloat(this.responsibility.user_responsibility_log.value).toFixed(2))
						: "";
					this.valueDebounce = this.responsibility.user_responsibility_log.evaluated
						? parseFloat(parseFloat(this.responsibility.user_responsibility_log.value).toFixed(2))
						: "";
					this.description = this.responsibility.user_responsibility_log.description
						? this.responsibility.user_responsibility_log.description
						: "";
					this.savedFiles = this.responsibility.user_responsibility_log.files;
					this.savedFiles.length != 0 ? (this.hasFile = "true") : "";
				}

				if (data.data.procedures && data.data.procedures.length && this.data.from == "user") {
					axios
						.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/${this.data.userSlug}/procedure-checks`, {
							params: { procedures_ids: data.data.procedures.map((el) => el.id) },
						})
						.then(({ data }) => {
							this.proceduresTracking = data.data;

							var arrayCheckProcedures = this.responsibility.procedures.map((procedure_run) => {
								var dataProcedure = this.proceduresTracking.filter((el) => el.procedure.id == procedure_run.id);

								if (dataProcedure.find((el) => el.status == "closed" && el.completed == 1)) {
									return "yes";
								} else if (dataProcedure.find((el) => el.status == "open")) {
									return "no";
								} else {
									return "no";
								}
							});

							this.needCompleteResponsibilityWithProcedures =
								this.responsibility.procedure_check === 1 ? arrayCheckProcedures.find((el) => el === "no") : false;
						})
						.finally(() => {
							this.loaded = true;
							this.loadedProcedures = true;
						});
				} else {
					this.loaded = true;
				}
			});
		},
		closeModal() {
			this.$emit("close_modal");
		},
		saveUpdates(type) {
			var buttonName = `buttonUpdate`;
			var btnSubmit = document.getElementById(buttonName);
			var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`);
			var btnSubmitText = document.querySelector(`#${buttonName} .text`);

			btnSubmit.disabled = true;
			btnSubmit.classList.add("loading");
			btnSubmitLoader.classList.add("onProgress");
			btnSubmitText.innerHTML = this.$t("btn-submit.loading");

			if (
				this.showTextarea != "" &&
				((this.responsibility.type == "check" && this.complete == "") || (this.responsibility.type == "value" && this.value == ""))
			) {
				if (!this.description_review) {
					this.error_description_review = true;
					btnSubmitLoader.classList.add("finish");
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.error");
						btnSubmit.classList.add("error");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							btnSubmit.classList.remove("error");
							btnSubmitText.innerHTML = this.$t("general.update");
							btnSubmit.disabled = false;
						}, 1000);
					}, 300);
				} else {
					this.error_description_review = false;
					this.sendReviewManager(true);
				}
			} else {
				this.$v.$touch();

				if (!this.$v.$invalid) {
					let objData = new FormData();
					objData.append("role_responsibility_id", this.data.id);
					objData.append("date", this.data.date);
					objData.append("free_day", this.free_day ? 1 : 0);
					objData.append("description", this.description);
					objData.append("value", this.value ? this.value : 0);
					objData.append("completed", this.complete);

					for (var i = 0; i < this.savedFiles.length; i++) {
						if (this.savedFiles[i]) {
							let fileItem = this.savedFiles[i];

							if (fileItem.file) {
								objData.append("files[" + i + "][file]", fileItem.file);
								if (fileItem.id) {
									objData.append("files[" + i + "][id]", fileItem.id);
								}
							}
						}
					}

					if (this.awaitingFilesForRemove.length) {
						for (var i = 0; i < this.awaitingFilesForRemove.length; i++) {
							let fileRemoveId = this.awaitingFilesForRemove[i];
							objData.append("remove_files_ids[" + i + "]", fileRemoveId);
						}
					}

					if (!this.free_day) {
						if (this.responsibility.type != "check") {
							objData.append("completed", this.checkPositiveClass() ? 1 : 0);
						}
					}

					if (this.showTextarea != "") {
						this.sendReviewManager();
					}

					if (type == "create") {
						this.createLog(objData, btnSubmit, btnSubmitText, btnSubmitLoader);
					} else if (type == "update") {
						this.updateLog(objData, btnSubmit, btnSubmitText, btnSubmitLoader);
					}
				} else {
					btnSubmitLoader.classList.add("finish");
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.error");
						btnSubmit.classList.add("error");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							btnSubmit.classList.remove("error");
							btnSubmitText.innerHTML = this.$t("general.update");
							btnSubmit.disabled = false;
						}, 1000);
					}, 300);
				}
			}
		},
		createLog(objData, btnSubmit, btnSubmitText, btnSubmitLoader) {
			axios
				.post(`/${this.data.userSlug}/user-responsibility-logs/store`, objData, {
					headers: { "Content-Type": "multipart/form-data" },
				})
				.then(({ data }) => {
					this.$root.$emit("updateEventCalendar", {
						id: this.data.unique_id,
						start: data.data.start,
						className: data.data.className,
					});
					btnSubmitLoader.classList.add("finish");
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.success");
						btnSubmit.classList.add("completed");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							this.$root.$emit("refreshSplitView");
							this.$root.$emit("refresh_ToDo_List");
							this.$emit("close_modal");
						}, 500);
					}, 300);
				})
				.catch((error) => {
					btnSubmitLoader.classList.add("finish");
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.error");
						btnSubmit.classList.add("error");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							btnSubmit.classList.remove("error");
							btnSubmitText.innerHTML = this.$t("general.add");
							btnSubmit.disabled = false;
							if (error.response.status == 500) {
								this.error_message = this.$t("error.500");
							}
						}, 1000);
					}, 300);
				});
		},
		updateLog(objData, btnSubmit, btnSubmitText, btnSubmitLoader) {
			objData.append("_method", "PATCH");

			axios
				.post(`/user-responsibility-logs/${this.responsibility.user_responsibility_log.id}`, objData, {
					headers: { "Content-Type": "multipart/form-data" },
				})
				.then(({ data }) => {
					this.$root.$emit("updateEventCalendar", {
						id: this.data.unique_id,
						start: data.data.start,
						className: data.data.className,
					});
					btnSubmitLoader.classList.add("finish");
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.success");
						btnSubmit.classList.add("completed");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							this.$emit("close_modal");
							this.$root.$emit("refreshSplitView");
							this.$root.$emit("refresh_ToDo_List");
						}, 500);
					}, 300);
				})
				.catch((error) => {
					btnSubmitLoader.classList.add("finish");
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.error");
						btnSubmit.classList.add("error");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							btnSubmit.classList.remove("error");
							btnSubmitText.innerHTML = this.$t("general.add");
							btnSubmit.disabled = false;
							if (error.response.status == 500) {
								this.error_message = this.$t("error.500");
							}
						}, 1000);
					}, 300);
				});
		},
		checkShowWhyNot() {
			if (this.responsibility.type == "check" && this.complete === 0) {
				return true;
			} else if (this.responsibility.type == "value" && this.valueDebounce !== "" && this.checkNegativeClass()) {
				return true;
			} else if (this.free_day) {
				return true;
			} else {
				return false;
			}
		},
		updateValue(val, e) {
			this.valueDebounce = val;
		},
		checkPositiveClass() {
			if (this.responsibility.progress_type == "grow") {
				return parseFloat(this.valueDebounce) - parseFloat(this.responsibility.target) >= 0;
			} else {
				return parseFloat(this.valueDebounce) - parseFloat(this.responsibility.target) <= 0;
			}
		},
		checkNegativeClass() {
			if (this.responsibility.progress_type == "grow") {
				return parseFloat(this.valueDebounce) - parseFloat(this.responsibility.target) < 0;
			} else {
				return parseFloat(this.valueDebounce) - parseFloat(this.responsibility.target) > 0;
			}
		},
		sendReviewManager(withAnimation = false) {
			let reviewObjData = {};
			reviewObjData.user_id = this.data.userId;
			reviewObjData.role_responsibility_id = this.responsibility.id;
			reviewObjData.type = this.showTextarea;
			reviewObjData.body = this.description_review;

			axios.post("/reviews/store", reviewObjData).then(() => {
				if (withAnimation) {
					var buttonName = `buttonUpdate`;
					var btnSubmit = document.getElementById(buttonName);
					var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`);
					var btnSubmitText = document.querySelector(`#${buttonName} .text`);

					btnSubmit.disabled = true;
					btnSubmit.classList.add("loading");
					btnSubmitLoader.classList.add("onProgress");
					btnSubmitText.innerHTML = this.$t("btn-submit.loading");

					btnSubmitLoader.classList.add("finish");
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.success");
						btnSubmit.classList.add("completed");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							this.$emit("close_modal");
						}, 200);
					}, 200);
				}
			});
		},
		onFileChange(e) {
			this.fileError = "";

			var files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;

			this.fileUpload = files[0];
			this.hasFile = "true";

			this.addFile();
		},
		addFile() {
			if (this.fileUpload) {
				if (this.fileUpload.size <= 24999999) {
					let objectFile = {
						file: this.fileUpload,
						filename: this.fileUpload.name,
					};

					this.savedFiles.push(objectFile);
					this.fileUpload = "";
				} else {
					this.fileError = this.$t("validator.file_size");
				}
			}
		},
		deleteFile(file, index) {
			if (file.id) {
				this.awaitingFilesForRemove.push(file.id);
			}
			this.savedFiles.splice(index, 1);

			this.savedFiles.length == 0 ? (this.hasFile = "") : "";
		},
		classStatusProcedureRun(procedureID) {
			if (
				this.proceduresTracking
					.filter((el) => el.procedure.id == procedureID)
					.find((el) => el.status == "closed" && el.completed == 1)
			) {
				return "completed";
			} else if (this.proceduresTracking.filter((el) => el.procedure.id == procedureID).find((el) => el.status == "open")) {
				return "on-progress";
			}
		},
		showModalProcedureCheck(procedureRunId) {
			var arrayFiltered = this.proceduresTracking.filter((el) => el.procedure.id == procedureRunId);
			arrayFiltered.find((el) => el.status == "open");

			this.$root.$emit(
				"change_modal",
				"procedure_check",
				{ id: arrayFiltered.find((el) => el.status == "open").id, userSlug: this.$auth.user().slug },
				false
			);
		},
		startProcedureRun(procedureID) {
			axios
				.post(`${process.env.VUE_APP_PROCEDURES_LINK}/api/procedure-checks/store`, {
					procedure_id: procedureID,
					status: "open",
					checked_steps: [],
					total_steps: 0,
					completed: 0,
				})
				.then(({ data }) => {
					this.$root.$emit("change_modal", "procedure_check", { id: data.data.id, userSlug: this.$auth.user().slug }, false);
				});
		},
	},
};
</script>
