<template>
	<div class="modal-tbf modal-full-page modal-crud-responsibility crud-task modal_add_meeting">
		<div class="container-modal opacity-page" v-if="loaded">
			<div class="header-modal">
				<div class="title-box">
					<div class="title-top" v-if="data.crud == 'edit'">
						{{ data.crud == "edit" ? $t("add_meeting.edit_meeting") : $t("add_meeting.add_meeting") }}
					</div>
					<div class="title">
						{{ data.crud == "edit" ? data.date : $t("add_meeting.add_meeting") }}
					</div>
				</div>
				<div class="separator"></div>

				<!-- gooogle meeting link -->
				<!-- <div class="btn-action-task meetingsLink" v-bind:class="{active: googleMeetActive}" @click="googleMeetActive = !googleMeetActive">
                    <icon-google-meet class="icon"/>
                    <template v-if="googleMeetActive">
                        <input @click.stop type="text" placeholder="linkul">
                        <span class="copy-btn">copy</span>
                    </template>
                </div> -->

				<!-- microsoft teams link -->
				<!-- <div class="btn-action-task meetingsLink" v-bind:class="{active: microsoftTeamsActive}" @click="microsoftTeamsActive = !microsoftTeamsActive">
                    <icon-microsoft-teams class="icon"/>
                    <template v-if="microsoftTeamsActive">
                        <input @click.stop type="text" placeholder="linkul">
                        <span class="copy-btn">copy</span>
                    </template>
                </div> -->

				<!-- zoom link -->
				<!-- <div class="btn-action-task meetingsLink" v-bind:class="{active: zoomActive}" @click="zoomActive = !zoomActive">
                    <icon-zoom class="icon"/>
                    <template v-if="zoomActive">
                        <input @click.stop type="text" placeholder="linkul">
                        <span class="copy-btn">copy</span>
                    </template>
                </div> -->

				<!-- <div class="separator"></div> -->

				<!-- notification -->
				<div
					class="btn-action-task"
					v-if="!notificationActive && !notificationCompleted"
					@click="notificationActive = !notificationActive"
				>
					<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 0, hide: 0 }">
						<div class="icon">
							<icon-bell />
						</div>

						<template slot="popover">
							<div class="simple-text">{{ $t("add_meeting.notification") }}</div>
						</template>
					</v-popover>
				</div>

				<div class="notification-meeting" v-else-if="!notificationCompleted">
					<div class="input-box">
						<div class="icon-left clickable" @click="checkNotification('open')"><icon-bell /></div>
						<input type="number" class="input-text" v-model="meeting.notification.value" />
					</div>

					<div class="input-box bigger">
						<div class="icon-left"><icon-hourglass /></div>
						<multiselect
							class="hide-tags multiselect_checkbox"
							v-model="meeting.notification.type"
							open-direction="bottom"
							:options="optionsTime"
							:allow-empty="false"
							:show-labels="false"
							:multiple="false"
							:maxHeight="200"
							track-by="id"
							label="label"
						>
							<template slot="noResult">{{ $t("departments.no-results") }}</template>
							<template slot="noOptions">{{ $t("departments.empty-list") }}</template>
						</multiselect>
					</div>
				</div>

				<div class="dropdown-with-input icon-dropdown" v-if="notificationCompleted">
					<div class="title-top">{{ $t("add_meeting.notification") }}</div>
					<button class="btn-inline-value" @click="checkNotification('close')">
						<div class="icon">
							<icon-bell />
						</div>
						<span class="text">{{ meeting.notification.value + " " + meeting.notification.type.label }}</span>
					</button>
				</div>

				<div class="actions">
					<button class="btn-tbf white only-icon edit-btn" @click="openSubModal('delete')" v-if="data.crud == 'edit'">
						<icon-trash class="icon-close" />
					</button>
					<button class="btn-tbf white only-icon edit-btn" @click="openViewOnly()" v-if="data.crud == 'edit'">
						<icon-eye class="icon-close" />
					</button>
					<button class="btn-tbf white only-icon close-btn" @click="closeModal()"><icon-close class="icon-close" /></button>
				</div>
			</div>
			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<!-- add name -->
						<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
							<div class="label-header">
								<label class="label">{{ $t("general.name") }}*</label>
								<div v-if="$v.meeting.title.$error" class="error-msg">
									{{ !$v.meeting.title.required ? $t("validator.required") : $t("validator.maxlength_255") }}
								</div>
							</div>
							<div class="input-box bg-white" v-bind:class="{ has_error: $v.meeting.title.$error }">
								<div class="icon-left"><icon-circle-check /></div>
								<input
									type="text"
									:placeholder="$t('general.name_placeholder')"
									class="input-text no-icon"
									v-model="meeting.title"
								/>
								<div class="icon-right" v-if="meeting.title != ''" @click.stop="meeting.title = ''">
									<icon-close class="icon-clear" />
								</div>
							</div>
						</div>
						<!-- add guests -->
						<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
							<div class="label-header">
								<label class="label">{{ $t("add_meeting.participants") }}</label>
								<div v-if="emailError" class="error-msg">{{ $t("add_meeting.not_an_email") }}</div>
							</div>
							<div class="input-box bg-white">
								<div class="icon-left"><icon-people /></div>
								<!-- <div class="icon-right" v-if="meeting.guests != ''" @click.stop="meeting.guests = ''"><icon-close class="icon-clear" /></div> -->
								<multiselect
									class="hide-tags multiselect_checkbox new-email"
									v-model="usersSelected"
									open-direction="bottom"
									:close-on-select="false"
									:taggable="true"
									:options="optionsUsers"
									:allow-empty="true"
									:show-labels="false"
									:multiple="true"
									:maxHeight="200"
									track-by="id"
									label="name"
									@tag="addNewGuest"
								>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{ $t("add_meeting.choose_participants") }}
										</span>
									</template>
									<template slot="selection" slot-scope="{ values, search, isOpen }">
										<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{
											values[0].name
										}}</span>
										<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen"
											>{{ values.length }} {{ $t("filters.selected") }}</span
										>
									</template>
									<template slot="option" slot-scope="{ option }" v-if="!option.is_external">
										<div class="option__desc" v-bind:class="{ captain: isCaptain(option), secund: isSecond(option) }">
											<span class="option__title">{{ option.name }}</span>
											<span class="option__icon" v-if="isCaptain(option)">
												<v-popover
													offset="5"
													trigger="hover"
													placement="bottom"
													popoverBaseClass="popover-tbf"
													:delay="{ show: 200, hide: 0 }"
												>
													<icon-user-crowned />
													<template slot="popover">
														<div class="simple-text">{{ $t("add_meeting.captain") }}</div>
													</template>
												</v-popover>
											</span>
											<span class="option__icon" @click.stop="updateUserRight(option)" v-else>
												<v-popover
													offset="5"
													trigger="hover"
													placement="bottom"
													popoverBaseClass="popover-tbf"
													:delay="{ show: 200, hide: 0 }"
												>
													<icon-editors />
													<template slot="popover">
														<div class="simple-text">{{ $t("add_meeting.second") }}</div>
													</template>
												</v-popover>
											</span>
										</div>
									</template>
									<template slot="noResult">{{ $t("departments.no-results") }}</template>
									<template slot="noOptions">{{ $t("departments.empty-list") }}</template>
								</multiselect>
							</div>
						</div>

						<!-- add physical location -->
						<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
							<div class="label-header">
								<label class="label">{{ $t("add_meeting.physical_location") }}</label>
							</div>
							<div class="input-box bg-white">
								<div class="icon-left"><icon-location /></div>
								<input
									type="text"
									:placeholder="$t('add_meeting.placeholder_physical_location')"
									class="input-text no-icon"
									v-model="meeting.location"
								/>
								<div class="icon-right" v-if="meeting.location" @click.stop="meeting.location = ''">
									<icon-close class="icon-clear" />
								</div>
							</div>
						</div>

						<!-- add online location -->
						<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
							<div class="label-header">
								<label class="label">{{ $t("add_meeting.online_location") }}</label>
							</div>
							<div class="input-box bg-white">
								<div class="icon-left"><icon-location /></div>
								<input
									type="text"
									:placeholder="$t('add_meeting.placeholder_online_link')"
									class="input-text no-icon"
									v-model="meeting.link"
								/>
								<div class="icon-right" v-if="meeting.link" @click.stop="meeting.link = ''">
									<icon-close class="icon-clear" />
								</div>
							</div>
						</div>

						<!-- alege tip intalnire -->
						<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
							<div class="label-header">
								<label class="label">{{ $t("add_meeting.type.name") }}</label>
							</div>
							<div class="input-box bg-white">
								<div class="icon-left"><icon-settings /></div>
								<multiselect
									class="hide-tags multiselect_checkbox"
									v-model="meeting.typeSelected"
									open-direction="bottom"
									:options="optionsMeetingType"
									:show-labels="false"
									:searchable="false"
									:maxHeight="200"
									track-by="id"
									label="name"
									@input="clearTopic"
								>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{ $t("add_meeting.type.ph") }}
										</span>
									</template>
								</multiselect>
							</div>
						</div>
						<!-- alege tip intalnire -->
						<template v-if="meeting.typeSelected.type != 'evaluation'">
							<div
								class="input-group"
								:class="[
									$resize && $mq.below(600) ? 'w-100' : 'w-50',
									{ disabled: meeting.typeSelected.type == 'daily_tasks' },
								]"
							>
								<div class="label-header">
									<label class="label">{{ $t("add_meeting.subject") }}</label>
									<div v-if="$v.meetingTopic.$error" class="error-msg">{{ $t("validator.required") }}</div>
								</div>
								<div class="input-box bg-white">
									<div class="icon-left"><icon-messages /></div>
									<div class="input-text-link" @click="openSubModal('choose_topic')">
										{{ $tc("add_meeting.choose_topic", meetingTopic.length) }}
									</div>
								</div>
							</div>
						</template>
						<template v-else>
							<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
								<div class="label-header">
									<label class="label">Colegul evaluat</label>
								</div>
								<div class="input-box bg-white">
									<div class="icon-left"><icon-user /></div>
									<multiselect
										class="hide-tags multiselect_checkbox"
										v-model="meeting.evaluatedUser"
										open-direction="bottom"
										:options="optionsUsers"
										:allow-empty="true"
										:show-labels="false"
										:maxHeight="200"
										track-by="id"
										label="name"
									>
										<template slot="placeholder" slot-scope="props">
											<span class="text">
												Alege colegul evaluat
											</span>
										</template>
										<template slot="noResult">{{ $t("departments.no-results") }}</template>
										<template slot="noOptions">{{ $t("departments.empty-list") }}</template>
									</multiselect>
								</div>
							</div>
						</template>

						<!-- add description -->
						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t("responsibility.description") }}</label>
							</div>
							<div class="input-box bg-white">
								<quill-editor
									class="editor-tbf h-150"
									ref="myQuillEditor"
									v-model="meeting.description"
									:options="editorOption"
								/>
							</div>
						</div>

						<!-- add frequency -->
						<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
							<div class="label-header">
								<label class="label">{{ $t("responsibility.frequency") }}</label>
								<div v-if="$v.meeting.frequency.$error" class="error-msg">{{ $t("validator.required") }}</div>
							</div>
							<div class="flex">
								<div class="input-box bg-white w-flex" v-bind:class="{ has_error: $v.meeting.frequency.$error }">
									<div class="icon-left"><icon-repeat /></div>
									<multiselect
										v-model="meeting.frequency"
										:options="optionsFrequency"
										:allow-empty="false"
										:show-labels="false"
										open-direction="top"
										@select="checkFrequency"
									>
										<template slot="placeholder" slot-scope="props">
											<span class="text">
												{{ $t("responsibility.choose-frequency") }}
											</span>
										</template>
										<template slot="singleLabel" slot-scope="props">{{
											$t(`responsibility.frequencies.${props.option}`)
										}}</template>
										<template slot="option" slot-scope="props">{{
											$t(`responsibility.frequencies.${props.option}`)
										}}</template>
										<template slot="noResult">{{ $t("general.no_results") }}</template>
										<template slot="noOptions">{{ $t("general.empty_list") }}</template>
									</multiselect>
								</div>
							</div>
						</div>

						<!-- add date and hour -->
						<!-- frecventa one time -->
						<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
							<div class="label-header">
								<label class="label" v-if="meeting.frequency == 'one_time'">{{ $t("add_meeting.date_and_hour") }}*</label>
								<label class="label" v-else>{{ $t("add_task.choose_hour") }}*</label>
								<div
									v-if="$v.meeting.lastMeeting.$error || $v.meeting.startHour.$error || $v.meeting.endHour.$error"
									class="error-msg"
								>
									{{ !$v.meeting.lastMeeting.required ? $t("validator.required") : $t("validator.maxlength_255") }}
								</div>
							</div>
							<div class="flex" :class="{ wide: meeting.frequency != 'one_time' }">
								<div
									class="input-box bg-white w-flex"
									v-bind:class="{ has_error: $v.meeting.lastMeeting.$error }"
									v-if="meeting.frequency == 'one_time'"
								>
									<div class="icon-left"><icon-calendar /></div>
									<div
										class="icon-right"
										v-if="Object.keys(meeting.lastMeeting).length"
										@click.stop="meeting.lastMeeting = {}"
									>
										<icon-close class="icon-clear" />
									</div>
									<FunctionalCalendar
										ref="CalendarDateOfEmployment"
										v-model="meeting.lastMeeting"
										class="calendar-tbf deadline"
										:configs="configsDate"
									>
										<template v-slot:datePickerInput="props">
											<input
												class="vfc-single-input custom-input-picker"
												type="text"
												:value="moment(props.selectedDate, 'D/M/YYYY').format('DD MMM YYYY')"
												v-if="Object.keys(meeting.lastMeeting).length"
											/>

											<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t("general.date") }}</div>
										</template>
									</FunctionalCalendar>
								</div>
								<template>
									<div class="clock active" v-bind:class="{ has_error: $v.meeting.startHour.$error }">
										<div class="icon-clock"><icon-clock-two class="icon" /></div>
										<input
											v-model="meeting.startHour"
											id="timeInput"
											type="text"
											class="time-picker"
											placeholder="Start"
										/>
										<div class="icon-right" v-if="meeting.startHour != ''" @click.stop="meeting.startHour = ''">
											<icon-close class="icon-clear" />
										</div>
									</div>
									<div class="clock active" v-bind:class="{ has_error: $v.meeting.endHour.$error }">
										<div class="icon-clock"><icon-clock-five class="icon" /></div>
										<input
											v-model="meeting.endHour"
											id="timeInputEnd"
											type="text"
											class="time-picker"
											placeholder="End"
										/>
										<div class="icon-right" v-if="meeting.endHour != ''" @click.stop="meeting.endHour = ''">
											<icon-close class="icon-clear" />
										</div>
									</div>
								</template>
							</div>
						</div>
						<template v-if="meeting.frequency != 'one_time'">
							<!-- frecventa yearly -->

							<div class="input-group w-100" v-if="meeting.frequency == 'yearly'">
								<div class="label-header">
									<label class="label">{{ $t("add_task.select_date") }}</label>
									<div v-if="$v.meeting.yearlyDays.$error" class="error-msg">{{ $t("validator.required") }}</div>
								</div>
								<div class="input-box bg-white-all-time">
									<FunctionalCalendar
										ref="CalendarDateOfEmployment"
										v-model="meeting.dateOfYear"
										class="calendar-tbf frequency"
										:is-multiple-date-picker="true"
										:is-multiple="true"
										:calendars-count="3"
										:is-separately="false"
										:change-month-step="3"
										:hidden-elements="['leftAndRightDays']"
										:limits="limitsRange"
										:newCurrentDate="newStartDay"
										@dayClicked="selectDay($event, 'yearly')"
									>
										<template v-slot:datePickerInput="props">
											<input
												class="vfc-single-input custom-input-picker"
												type="text"
												:value="props.selectedDate ? moment(props.selectedDate, 'YYYY-M-D').format('DD MMMM') : ''"
												v-if="Object.keys(meeting.dateOfYear).length"
											/>

											<div class="vfc-single-input custom-placeholder-picker" v-else>
												{{ $t("add_task.select_date") }}
											</div>
										</template>
									</FunctionalCalendar>
								</div>
							</div>

							<template v-if="meeting.frequency != ''">
								<!-- frecventa lunara -->

								<div class="input-group w-50 measurement" v-if="meeting.frequency == 'monthly'">
									<div class="label-header">
										<label class="label">{{ $t("responsibility.type_monthly_label") }}</label>
										<div v-if="$v.meeting.monthlyTypeSequenceDay.$error" class="error-msg">
											{{ $t("validator.required") }}
										</div>
									</div>
									<div class="input-box-with-dropdown">
										<div class="input-box box-select small-padd">
											<multiselect
												v-model="meeting.subTypeMonthly"
												:options="optionsMonthlyTypes"
												:allow-empty="false"
												:show-labels="false"
												:placeholder="$t('general.choose')"
											>
												<template slot="singleLabel" slot-scope="props">{{
													$t(`responsibility.type_monthly.${props.option}`)
												}}</template>
												<template slot="option" slot-scope="props">{{
													$t(`responsibility.type_monthly.${props.option}`)
												}}</template>
												<template slot="noResult">{{ $t("general.no_results") }}</template>
												<template slot="noOptions">{{ $t("general.empty_list") }}</template>
											</multiselect>
										</div>

										<div class="input-box value-type" v-if="meeting.subTypeMonthly == 'day_numbers'">
											<div class="icon-left"><icon-circle-check /></div>
											<div class="input-text-simple">{{ $t("responsibility.type_check_day_numbers") }}</div>
										</div>
										<div
											class="input-box value-type"
											v-bind:class="{ has_error: $v.meeting.monthlyTypeSequenceDay.$error }"
											v-else-if="meeting.subTypeMonthly == 'sequence_days'"
										>
											<div class="icon-left"><icon-repeat /></div>
											<multiselect
												class="hide-tags multiselect_checkbox"
												v-model="meeting.monthlyTypeSequenceDay"
												v-bind:class="{ populate: meeting.monthlyTypeSequenceDay }"
												:options="optionsMonthlyTypeSequenceDays"
												:allow-empty="true"
												:show-labels="false"
												:close-on-select="false"
												:multiple="true"
												track-by="value"
												label="name"
											>
												<template slot="placeholder" slot-scope="props">
													<span class="text">
														{{ $t("responsibility.choose_monthly_sequence_days") }}
													</span>
												</template>
												<template slot="selection" slot-scope="{ values, search, isOpen }">
													<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{
														$t(`responsibility.monthly_sequence_days_option.${values[0].name}`)
													}}</span>
													<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen"
														>{{ values.length }} {{ $t("filters.selected") }}</span
													>
												</template>
												<template slot="option" slot-scope="props">
													<div class="checkbox">
														<div class="checkmark"></div>
														<span class="text">{{
															$t(`responsibility.monthly_sequence_days_option.${props.option.name}`)
														}}</span>
													</div>
												</template>
												<template slot="noResult">{{ $t("general.no_results") }}</template>
												<template slot="noOptions">{{ $t("general.empty_list") }}</template>
											</multiselect>
										</div>
									</div>
								</div>

								<!-- frecventa saptamana sau lunara -->

								<div
									class="input-group w-50 measurement"
									v-if="
										meeting.frequency == 'weekly' ||
											(meeting.frequency == 'monthly' && meeting.subTypeMonthly == 'sequence_days')
									"
								>
									<div class="label-header">
										<label class="label">{{ $t("add_task.select_date") }}</label>
										<div v-if="$v.meeting.weeklyDays.$error" class="error-msg">{{ $t("validator.required") }}</div>
									</div>
									<div class="input-select-days" v-bind:class="{ has_error: $v.meeting.weeklyDays.$error }">
										<div class="select-days weekly">
											<div class="checkbox-days" v-for="day in optionsWeeklyDays">
												<span
													@click="selectDay(day, 'weekly')"
													class="checkmark"
													v-bind:class="{ active: meeting.weeklyDays.includes(day) }"
													>{{ $t(`calendar.small_days.${day.name}`) }}</span
												>
											</div>
										</div>
									</div>
								</div>
								<div class="input-group w-100 measurement" v-else-if="meeting.frequency == 'monthly'">
									<div class="label-header">
										<label class="label">{{ $t("add_task.select_date") }}</label>
										<div v-if="$v.meeting.monthlyDays.$error" class="error-msg">{{ $t("validator.required") }}</div>
									</div>
									<div class="input-select-days" v-bind:class="{ has_error: $v.meeting.monthlyDays.$error }">
										<div class="select-days">
											<div class="checkbox-days" v-for="day in optionsMonthlyDays">
												<span
													@click="selectDay(day, 'monthly')"
													class="checkmark"
													v-bind:class="{ active: meeting.monthlyDays.includes(day) }"
													>{{ day }}</span
												>
											</div>
										</div>
									</div>
								</div>
							</template>

							<div class="clear-100"></div>
							<!-- date of start -->
							<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
								<div class="label-header">
									<label class="label">{{ $t("add_task.start_date") }}</label>
									<div v-if="$v.meeting.firstMeeting.$error" class="error-msg">{{ $t("validator.required") }}</div>
								</div>
								<div class="input-box bg-white w-flex" v-bind:class="{ has_error: $v.meeting.firstMeeting.$error }">
									<div class="icon-left"><icon-calendar /></div>
									<div
										class="icon-right"
										v-if="Object.keys(meeting.firstMeeting).length"
										@click.stop="meeting.firstMeeting = {}"
									>
										<icon-close class="icon-clear" />
									</div>
									<FunctionalCalendar
										ref="CalendarDateOfEmployment"
										v-model="meeting.firstMeeting"
										v-on:choseDay="newStartDate"
										class="calendar-tbf deadline"
										:configs="calendarConfigs"
									>
										<template v-slot:datePickerInput="props">
											<input
												class="vfc-single-input custom-input-picker"
												type="text"
												:value="moment(props.selectedDate, 'D/M/YYYY').format('DD MMM YYYY')"
												v-if="Object.keys(meeting.firstMeeting).length"
											/>

											<div class="vfc-single-input custom-placeholder-picker" v-else>
												{{ $t("add_task.choose_start_date") }}
											</div>
										</template>
									</FunctionalCalendar>
								</div>
							</div>
							<!-- date of last Meeting -->
							<div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
								<div class="label-header">
									<label class="label">{{ $t("add_task.end_date") }}</label>
								</div>
								<div class="flex">
									<div class="input-box bg-white w-flex">
										<div class="icon-left"><icon-calendar /></div>
										<div
											class="icon-right"
											v-if="Object.keys(meeting.lastMeeting).length"
											@click.stop="meeting.lastMeeting = {}"
										>
											<icon-close class="icon-clear" />
										</div>
										<FunctionalCalendar
											ref="CalendarDateOfEmployment"
											v-model="meeting.lastMeeting"
											class="calendar-tbf deadline"
											:configs="configsDateOfFinish"
											:key="keyCalendarEndDate"
										>
											<template v-slot:datePickerInput="props">
												<input
													class="vfc-single-input custom-input-picker"
													type="text"
													:value="moment(props.selectedDate, 'D/M/YYYY').format('DD MMM YYYY')"
													v-if="Object.keys(meeting.lastMeeting).length"
												/>

												<div class="vfc-single-input custom-placeholder-picker" v-else>
													{{ $t("add_task.choose_end_date") }}
												</div>
											</template>
										</FunctionalCalendar>
									</div>
								</div>
							</div>
						</template>
					</div>

					<div class="form-submit">
						<button
							id="buttonPrimarySubmit"
							class="btn-tbf blue center"
							@click="saveMeeting(data.crud == 'edit' ? 'update' : 'create')"
						>
							<div class="loader-spin" v-if="loadingSaveMeeting"></div>
							<div class="text" v-else>{{ data.crud == "edit" ? $t("general.update") : $t("general.add") }}</div>
						</button>
					</div>
				</div>
				<!-- container pt submodal -->

				<div id="submodalContainer" class="submodal-container" v-if="subModalShow">
					<div class="overlay-submodal"></div>
					<modal-delete
						id="submodal_delete"
						v-if="submodal.delete"
						:data="{
							type: 'meeting',
							model: { id: meetingId, is_recurrence: oldData.frequency ? true : false, date: data.date },
							from: 'index_tasks',
						}"
						@close_modal="$emit('close_modal')"
						@cancel_close="closeSubModal('delete')"
					/>
					<modal-confirm-close
						id="submodal_confirm_close"
						v-if="submodal.confirm_close"
						@close_modal="$emit('close_modal')"
						@cancel_close="closeSubModal('confirm_close')"
					/>
					<modal-choose-save-mode
						id="submodal_choose_save_mode"
						v-if="submodal.choose_save_mode"
						:options="optionsSaveMode"
						:data="dataChooseSaveMode"
						@cancel_close="closeSubModal('choose_save_mode')"
					/>
					<modal-choose-topic
						id="submodal_choose_topic"
						v-if="submodal.choose_topic"
						:options="JSON.stringify(meetingTopic)"
						:meetingType="meeting.typeSelected"
						@cancel_close="closeSubModal('choose_topic')"
						@updateTopic="updateTopic"
					/>
				</div>
			</div>
		</div>
		<department-loader-modal v-else />
	</div>
</template>

<script type="text/javascript">
import DepartmentLoaderModal from "../PagesLoaders/DepartmentModal.vue";

import IconClose from "../Icons/Close";
import IconCircleCheck from "../Icons/CircleCheck";
import IconCalendar from "../Icons/Calendar";
import IconClock from "../Icons/Clock";
import IconClockTwo from "../Icons/ClockTwo";
import IconClockFive from "../Icons/ClockFive";
import IconPeople from "../Icons/People";
import IconLocation from "../Icons/Location";
import IconBell from "../Icons/Bell";
import IconHourglass from "../Icons/HourglassClock";
import IconRepeat from "../Icons/Repeat";
import IconGoogleMeet from "../Icons/GoogleMeet";
import IconMicrosoftTeams from "../Icons/MicrosoftTeams";
import IconZoom from "../Icons/Zoom";
import IconTrash from "../Icons/Trash";
import IconEditDots from "../Icons/EditDots";
import IconEye from "../Icons/Eye";
import IconUserCrowned from "../Icons/UserCrowned";
import IconEditors from "../Icons/Editors";
import IconSettings from "../Icons/SettingsGear";
import IconMessages from "../Icons/Messages";
import IconUser from "../Icons/User";

import ModalDelete from "../Modals/Delete";
import ModalConfirmClose from "../Modals/ConfirmClose";
import ModalEditTag from "../Modals/EditTag";
import ModalChooseSaveMode from "../Modals/ChooseSaveMode";
import ModalChooseTopic from "../Modals/ChooseMeetingTopic";

import { required, requiredIf, maxLength, helpers } from "vuelidate/lib/validators";

export default {
	components: {
		DepartmentLoaderModal,
		IconClose,
		IconCircleCheck,
		IconCalendar,
		IconClock,
		IconPeople,
		IconLocation,
		IconClockTwo,
		IconClockFive,
		IconBell,
		IconHourglass,
		IconRepeat,
		IconGoogleMeet,
		IconMicrosoftTeams,
		IconZoom,
		IconTrash,
		IconEditDots,
		IconEye,
		IconUserCrowned,
		IconEditors,
		IconSettings,
		IconMessages,
		IconUser,
		ModalDelete,
		ModalConfirmClose,
		ModalEditTag,
		ModalChooseSaveMode,
		ModalChooseTopic,
	},
	props: {
		data: Object,
	},
	data() {
		return {
			loaded: false,
			keyCalendarEndDate: 1,
			optionsUsers: [],
			editorOption: {
				modules: {
					toolbar: [
						[{ header: [1, 2, 3, false] }],
						["bold", "italic", "underline", "strike"],
						["blockquote", "code-block"],
						[{ list: "ordered" }, { list: "bullet" }, { align: [] }],
						[{ color: [] }, { background: [] }],
						["link", "image", "video"],
						["clean"],
					],
					imageUploader: {
						upload: (file) => {
							return new Promise((resolve, reject) => {
								let objData = new FormData();
								objData.append("file", file);
								objData.append("type", "meetings");

								axios.post(`/instances/${this.$auth.user().instance.id}}/upload-image`, objData).then(({ data }) => {
									resolve(data.file_path);
								});
							});
						},
					},
				},
			},
			configsDate: {
				isDatePicker: true,
				isDateRange: false,
				isModal: true,
				isAutoCloseable: true,
				changeMonthFunction: true,
				disabledDates: ["beforeToday"],
				changeYearFunction: true,
				limits: {
					min: "",
					max: "",
				},
			},
			optionsTime: [
				{
					id: "0",
					name: "hours",
					label: this.$t("add_meeting.hours"),
				},
				{
					id: "1",
					name: "days",
					label: this.$t("add_meeting.days"),
				},
				{
					id: "2",
					name: "weeks",
					label: this.$t("add_meeting.weeks"),
				},
			],
			optionsSaveMode: ["current", "current_and_future", "all"],
			meetingId: "",
			oldData: {},
			meetingTopic: [],
			usersSelected: [],
			meeting: {
				parentMeetingId: "",
				title: "",
				description: "",
				startHour: "",
				endHour: "",
				location: "",
				link: "",
				guests: [],
				externalGuests: [],
				notification: {
					type: {
						id: "0",
						name: "hours",
						label: this.$t("add_meeting.hours"),
					},
					value: "1",
				},
				frequency: "one_time",
				firstMeeting: {},
				lastMeeting: {},
				weeklyDays: [],
				monthlyDays: [],
				monthlyTypeSequenceDay: [],
				yearlyDays: [],
				subTypeMonthly: "day_numbers",
				dateOfYear: {},
				typeSelected: { id: 0, type: "daily_tasks", name: this.$t("add_meeting.type.daily_tasks") },
				evaluatedUser: "",
			},
			optionsFrequency: ["one_time", "daily", "weekly", "monthly", "yearly"],
			optionsMonthlyTypes: ["day_numbers", "sequence_days"],
			optionsMonthlyTypeSequenceDays: [
				{ name: "first", value: 0 },
				{ name: "second", value: 1 },
				{ name: "third", value: 2 },
				{ name: "fourth", value: 3 },
				{ name: "last", value: 4 },
			],
			optionsWeeklyDays: [
				{ name: "monday", value: 1 },
				{ name: "tuesday", value: 2 },
				{ name: "wednesday", value: 3 },
				{ name: "thursday", value: 4 },
				{ name: "friday", value: 5 },
				{ name: "saturday", value: 6 },
				{ name: "sunday", value: 7 },
			],
			optionsMonthlyDays: Array.from({ length: 31 }, (_, i) => i + 1),
			limitsRange: { min: "", max: "" },
			newStartDay: "",
			calendarConfigs: {
				isDatePicker: true,
				isDateRange: false,
				isModal: true,
				isAutoCloseable: true,
				changeMonthFunction: true,
				changeYearFunction: true,
				disabledDates: ["beforeToday"],
			},
			configsDateOfFinish: {
				isDatePicker: true,
				isDateRange: false,
				isModal: true,
				isAutoCloseable: true,
				changeMonthFunction: true,
				changeYearFunction: true,
				limits: {
					min: "",
					max: "",
				},
			},
			googleMeetActive: false,
			microsoftTeamsActive: false,
			zoomActive: false,
			notificationActive: false,
			notificationCompleted: false,
			subModalShow: false,
			loadingSaveMeeting: false,
			dateMeeting: "",
			activeSubmodal: "",
			submodal: {
				confirm_close: false,
				delete: false,
				choose_save_mode: false,
			},
			dataChooseSaveMode: {},
			viewButtonsSave: true,
			emailError: false,
			optionsMeetingType: [
				{ id: 0, type: "daily_tasks", name: this.$t("add_meeting.type.daily_tasks") },
				{ id: 1, type: "task", name: this.$t("add_meeting.type.task") },
				{ id: 2, type: "project", name: this.$t("add_meeting.type.project") },
				{ id: 3, type: "objective", name: this.$t("add_meeting.type.objective") },
				{ id: 4, type: "key_result", name: this.$t("add_meeting.type.key_result") },
				{ id: 5, type: "custom_topic", name: this.$t("add_meeting.type.custom_topic") },
				// { id: 6 , type: 'evaluation', name: this.$t('add_meeting.type.evaluation')},
				{
					id: 7,
					type: "standard",
					name: this.$t("add_meeting.type.standard"),
					$isDisabled: [1, 4, 5].includes(this.$auth.user().instance.id) ? false : true,
				},
			],
		};
	},
	validations: {
		meeting: {
			title: { required, maxLength: maxLength(255) },
			weeklyDays: {
				required: requiredIf(function() {
					return (
						this.meeting.frequency == "weekly" ||
						(this.meeting.frequency == "monthly" && this.meeting.subTypeMonthly == "sequence_days")
					);
				}),
			},
			monthlyDays: {
				required: requiredIf(function() {
					return this.meeting.frequency == "monthly" && this.meeting.subTypeMonthly == "day_numbers";
				}),
			},
			yearlyDays: {
				required: requiredIf(function() {
					return this.meeting.frequency == "yearly";
				}),
			},
			monthlyTypeSequenceDay: {
				required: requiredIf(function() {
					return this.meeting.frequency == "monthly" && this.meeting.subTypeMonthly == "sequence_days";
				}),
			},
			frequency: { required },
			firstMeeting: {
				required: requiredIf(function() {
					return this.meeting.frequency != "one_time";
				}),
			},
			lastMeeting: {
				required: requiredIf(function() {
					return this.meeting.frequency == "one_time";
				}),
			},
			startHour: {
				required: requiredIf(function() {
					return this.meeting.frequency == "one_time";
				}),
			},
			endHour: {
				required: requiredIf(function() {
					return this.meeting.frequency == "one_time";
				}),
			},
		},
		meetingTopic: {
			required: requiredIf(function() {
				return this.meeting.typeSelected.type != "daily_tasks";
			}),
		},
	},
	created() {
		var startDate = moment().startOf("year");
		this.limitsRange.min = moment()
			.startOf("year")
			.format("D/M/YYYY");
		this.limitsRange.max = moment()
			.endOf("year")
			.format("D/M/YYYY");
		this.newStartDay = new Date(startDate);
	},
	async mounted() {
		await this.getUsers();

		if (this.data.crud == "edit") {
			await this.getMeeting();
		} else {
			if (this.data.end) {
				this.meeting.lastMeeting = {
					currentDate: moment().format("D/M/YYYY"),
					selectedDate: moment(this.data.end).format("D/M/YYYY"),
					multipleDateRange: [],
					selectedDates: [],
					selectedDateTime: false,
					selectedDatesItem: "",
					selectedHour: "00",
					selectedMinute: "00",
					dateRange: { end: "", start: "" },
				};
				this.meeting.startHour = moment(this.data.start).format("HH:mm");
				this.meeting.endHour = moment(this.data.end).format("HH:mm");

				this.configsDateOfFinish.limits = {
					min: moment(this.data.end).format("D/M/YYYY"),
					max: "",
				};
			}
		}

		var refreshWaitingLoaded = setInterval(() => {
			if (this.loaded) {
				this.toggleTimeInputs();
				clearInterval(refreshWaitingLoaded);
			}
		}, 50);

		this.$root.$on("confirm_close", () => {
			this.closeModal();
		});
	},
	methods: {
		async getMeeting() {
			// If has task_id, edit on parent else edit on log by task_log_id
			var paramsQuery = {};
			if (this.data.type == "meeting_log") {
				paramsQuery.id = this.data.meetingEditId;
			} else if (this.data.type == "meeting") {
				paramsQuery.meeting_id = this.data.meetingEditId;
			}

			await axios
				.get(`/meetings/${this.data.meetingEditId}/edit`)
				.then(({ data }) => {
					//Make a copy from currentData
					this.oldData = Object.freeze(JSON.parse(JSON.stringify(data.data)));

					this.meetingId = data.data.parent_id ? data.data.parent_id : data.data.id;
					this.dateMeeting = data.data.date;

					this.meetingTopic = data.data.topic;

					Object.assign(this.meeting, {
						parentMeetingId: data.data.parent_id ? data.data.parent_id : data.data.id,
						title: data.data.name,
						description: data.data.description,
						startHour: data.data.start_time,
						endHour: data.data.end_time,
						location: data.data.location,
						link: data.data.link,
						frequency: data.data.frequency ? data.data.frequency : "one_time",
						deadline: { date: {}, endHour: "" },
						notification: {
							value: data.data.notification_value,
							type: data.data.notification_type,
						},
						typeSelected: data.data.topic_type
							? this.optionsMeetingType.find((el) => el.type == data.data.topic_type)
							: this.optionsMeetingType.find((el) => el.type == "project"),
					});

					data.data.external_invites.map((el) => this.addNewGuest(el.email));

					if (data.data.users.length) {
						data.data.users.map((user) => {
							this.usersSelected.push({ id: user.id, right: user.right });
							if (user.right == "captain") {
								const captainUser = this.optionsUsers.find((el) => el.id == user.id);
								captainUser.$isDisabled = true;
							}
						});
					} else {
						var captainUser2 = this.optionsUsers.find((el) => el.id == this.$auth.user().id);
						captainUser2.$isDisabled = true;

						this.usersSelected.push({ ...captainUser2, right: "captain" });
					}

					if (data.data.notification_value) {
						switch (data.data.notification_type) {
							case "hours":
								this.meeting.notification.type = {
									id: "0",
									name: "hours",
									label: this.$t("add_meeting.hours"),
								};
								break;
							case "days":
								this.meeting.notification.type = {
									id: "1",
									name: "days",
									label: this.$t("add_meeting.days"),
								};
								break;
							case "weeks":
								this.meeting.notification.type = {
									id: "2",
									name: "weeks",
									label: this.$t("add_meeting.weeks"),
								};
								break;
						}
						this.notificationCompleted = true;
						this.notificationActive = true;
					}

					if (data.data.start_date) {
						this.meeting.firstMeeting = {
							selectedDate: moment(data.data.start_date, "YYYY-MM-DD").format("D/M/YYYY"),
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: { end: "", start: "" },
						};
						this.configsDateOfFinish.limits = {
							min: moment(data.data.date, "YYYY-MM-DD").format("D/M/YYYY"),
							max: "",
						};
					}

					if (data.data.deadline) {
						this.meeting.lastMeeting = {
							selectedDate: moment(data.data.deadline, "YYYY-MM-DD").format("D/M/YYYY"),
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: { end: "", start: "" },
						};
					}

					if (data.data.frequency) {
						switch (this.meeting.frequency) {
							case "one_time":
								// Case it's not recurrence
								break;
							case "weekly":
								this.meeting.weeklyDays = this.optionsWeeklyDays.filter((el) => data.data.measurement.includes(el.name));
								break;
							case "monthly":
								this.meeting.monthlyDays = data.data.measurement.map(function(a) {
									return parseInt(a);
								});
								break;
							case "monthly_dynamically":
								this.meeting.subTypeMonthly = "sequence_days";
								this.meeting.monthlyTypeSequenceDay = this.optionsMonthlyTypeSequenceDays.filter((el) =>
									data.data.measurement_dynamically.includes(el.value)
								);
								this.meeting.weeklyDays = this.optionsWeeklyDays.filter((el) => data.data.measurement.includes(el.value));
								this.meeting.frequency = "monthly";
								break;
							case "yearly":
								this.meeting.yearlyDays = data.data.measurement;
								this.meeting.dateOfYear = {
									selectedDate: false,
									selectedDateTime: false,
									multipleDateRange: [],
									selectedDatesItem: "",
									selectedHour: "00",
									selectedMinute: "00",
									dateRange: { end: "", start: "" },
									selectedDates: this.meeting.yearlyDays.map(function(a) {
										return { date: moment(a, "MM-DD").format("D/M/YYYY"), dateTime: false, hour: "00", minute: "00" };
									}),
								};
								break;
						}
					}
				})
				.finally(() => {
					this.loaded = true;
					setTimeout(() => {
						$(".opacity-page").addClass("show");
					}, 0);
				});
		},
		saveMeeting(type) {
			var isRecurrence = this.meeting.frequency != "one_time";
			this.loadingSaveMeeting = true;

			this.$v.$touch();

			// var captainUser = {
			//     id: this.$auth.user().id,
			//     right: 'captain'
			// };

			// if (!this.meeting.guests.some(el => el.id === captainUser.id)) {
			//     this.meeting.guests.push(captainUser);
			// }

			var internalUsers = [];

			this.usersSelected.map((el) => {
				if (el.is_external) {
					return;
				}
				internalUsers.push({ id: el.id, right: el.right ? el.right : null });
			});

			if (!this.$v.$invalid) {
				var object = {
					name: this.meeting.title,
					link: this.meeting.link ? this.meeting.link : "",
					start_time: this.meeting.startHour ? this.meeting.startHour : "",
					end_time: this.meeting.endHour ? this.meeting.endHour : "",
					description: this.meeting.description ? this.meeting.description : "",
					location: this.meeting.location ? this.meeting.location : "",
					frequency: isRecurrence ? this.meeting.frequency : "",
					notification_type: this.meeting.notification.value ? this.meeting.notification.type.name : "",
					notification_value: this.meeting.notification.value ? this.meeting.notification.value : "",
					external_invites: this.meeting.externalGuests,
					users: internalUsers,
					topic: this.meetingTopic,
					topic_type: this.meeting.typeSelected.type,
				};

				var lastMeeting = Object.keys(this.meeting.lastMeeting).length
					? moment(this.meeting.lastMeeting.selectedDate, "D/M/YYYY").format("YYYY-MM-DD")
					: "";
				var firstMeeting = Object.keys(this.meeting.firstMeeting).length
					? moment(this.meeting.firstMeeting.selectedDate, "D/M/YYYY").format("YYYY-MM-DD")
					: "";

				object.start_date = isRecurrence ? firstMeeting : lastMeeting;
				// this.data.crud == 'edit' && this.oldData.date ? this.data.date : firstMeeting
				object.date = isRecurrence ? (this.data.crud == "edit" ? this.data.date : firstMeeting) : lastMeeting;
				object.deadline = lastMeeting;

				object.measurement = "";
				object.measurement_dynamically = "";

				switch (isRecurrence && this.meeting.frequency) {
					case "weekly":
						object.measurement = this.meeting.weeklyDays.map((el) => {
							return el.name;
						});
						break;
					case "monthly":
						if (this.meeting.subTypeMonthly == "day_numbers") {
							object.measurement = this.meeting.monthlyDays;
						} else {
							object.measurement_dynamically = this.meeting.monthlyTypeSequenceDay.map((el) => {
								return el.value;
							});
							object.measurement = this.meeting.weeklyDays.map((el) => {
								return el.value;
							});
							object.frequency = "monthly_dynamically";
						}
						break;
					case "yearly":
						object.measurement = this.meeting.yearlyDays;
						break;
				}

				if (type == "create") {
					this.createMeeting(object);
				} else if (type == "update") {
					this.checkSaveMode(object);
				}
			} else {
				this.loadingSaveMeeting = false;
			}
		},
		createMeeting(objData) {
			axios.post("/meetings/store", objData).finally(() => {
				this.loadingSaveMeeting = false;
				this.$emit("close_modal");
				setTimeout(() => {
					this.$root.$emit("refreshSplitView");
					this.$root.$emit("refresh_ToDo_List");
				}, 200);
			});
		},
		checkSaveMode(objData) {
			var paramsQuery = {};
			this.loadingSaveMeeting = false;

			// from_date cand se muta un task fictiv
			this.optionsSaveMode = ["current", "current_and_future", "all"];

			// if task is log send Id
			if (this.data.type == "meeting_log") {
				paramsQuery.meeting_log_id = this.data.meetingEditId;
			}

			// We have only "current" option.
			// check if it is a simple meeting, if it has no frequency it is a simple meeting
			// if the date is from past
			// if press option with check

			if (
				this.oldData.frequency == "one_time" ||
				!this.oldData.frequency ||
				(this.data.date && moment(this.data.date).isBefore(moment().startOf("day")))
			) {
				paramsQuery.current = 1;

				this.updateMeeting(objData, paramsQuery);
			} else {
				// Daca se schimba frecventa, intervalul nu mai apare optiunea current
				if (
					!arrayEquals(this.oldData.measurement, objData.measurement) ||
					!arrayEquals(this.oldData.measurement_dynamically, objData.measurement_dynamically) ||
					!valueEquals(this.oldData.frequency, objData.frequency) ||
					!valueEquals(this.oldData.deadline, objData.deadline) ||
					// !valueEquals(this.oldData.deadline_time, objData.deadline_time) ||
					!valueEquals(this.oldData.start_date, objData.start_date)
				) {
					this.optionsSaveMode = ["current_and_future", "all"];
				}

				this.openSubModal("choose_save_mode", {
					objData: objData,
					paramsQuery: paramsQuery,
					activityDate: this.data.date,
					type: "meeting",
				});
			}
		},
		updateMeeting(objData, paramsQuery) {
			axios.post(`/meetings/${this.meetingId}/update`, objData, { params: paramsQuery }).then(() => {
				this.$root.$emit("refreshSplitView");
				this.$root.$emit("refresh_ToDo_List");
				this.$root.$emit("refreshProjectPage");
				this.$emit("close_modal");
			});
		},
		openSubModal(type, data = false) {
			if (this.activeSubmodal != "" && this.activeSubmodal != type) {
				document.getElementById(`submodal_${this.activeSubmodal}`).classList.remove("active");
				this.submodal[this.activeSubmodal] = false;
			}

			if (data) {
				this.dataChooseSaveMode = data;
			}

			this.subModalShow = true;
			this.submodal[type] = true;
			this.activeSubmodal = type;
			setTimeout(() => {
				document.getElementById("submodalContainer").classList.add("active");
				setTimeout(() => {
					document.getElementById(`submodal_${type}`).classList.add("active");
				}, 200);
			}, 0);
		},
		closeSubModal(type) {
			if (type == "choose_save_mode") {
				var buttonSubmit = document.getElementById("buttonPrimarySubmit");
				var buttonComplete = document.getElementById("buttonCompleteSubmit");
				if (buttonSubmit) {
					buttonSubmit.disabled = false;
				}
				if (buttonComplete) {
					buttonComplete.disabled = false;
				}
			}

			document.getElementById(`submodal_${type}`).classList.remove("active");
			setTimeout(() => {
				document.getElementById("submodalContainer").classList.remove("active");
				setTimeout(() => {
					this.subModalShow = false;
					this.submodal[type] = false;
					this.activeSubmodal = "";
				}, 200);
			}, 200);
		},
		closeModal() {
			if (this.checkInputs()) {
				this.openSubModal("confirm_close", this.submodal.confirm_close);
			} else {
				this.$emit("close_modal");
			}
		},
		toggleTimeInputs() {
			setTimeout(() => {
				$("#timeInput")
					.timepicker({ scrollDefault: "now", timeFormat: "H:i", show2400: false, step: "15" })
					.on("changeTime", () => {
						this.meeting.startHour = $("#timeInput").val();
					});
			}, 200);

			setTimeout(() => {
				$("#timeInputEnd")
					.timepicker({ scrollDefault: "now", timeFormat: "H:i", show2400: false, step: "15" })
					.on("changeTime", () => {
						this.meeting.endHour = $("#timeInputEnd").val();
					});
			}, 200);
		},
		async getUsers() {
			await axios
				.get(`instances/${this.$auth.user().instance.id}/filter`, { params: { users: true } })
				.then(({ data }) => {
					this.optionsUsers = data.data.users;

					if (this.data.crud != "edit") {
						const captainUser = this.optionsUsers.find((el) => el.id == this.$auth.user().id);
						captainUser.$isDisabled = true;
						this.usersSelected.push({ ...captainUser, right: "captain" });
					}
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					this.data.crud != "edit" ? (this.loaded = true) : "";
					setTimeout(() => {
						$(".opacity-page").addClass("show");
					}, 0);
				});
		},
		addNewGuest(newGuest) {
			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newGuest)) {
				const timestamp = Date.now().toString();
				const randomString = Math.random()
					.toString(36)
					.substring(2, 8);
				const uniqueString = timestamp + randomString;

				const guest = { name: newGuest, id: uniqueString, is_external: true };
				const externalGuest = { email: newGuest };

				// this.meeting.guests.push(guest);
				this.optionsUsers.push(guest);
				this.usersSelected.push(guest);
				this.meeting.externalGuests.push(externalGuest);
				this.emailError = false;
			} else {
				this.emailError = true;
			}
		},
		checkFrequency(frequency) {
			if (this.meeting.frequency == "one_time" && frequency != "one_time") {
				this.meeting.firstMeeting = this.meeting.lastMeeting;
				this.meeting.lastMeeting = {};
			} else if (frequency == "one_time") {
				this.meeting.lastMeeting = this.meeting.firstMeeting;
				this.meeting.firstMeeting = {};
			}
		},
		selectDay(day, list, fromList = false) {
			switch (list) {
				case "weekly":
					if (this.meeting.weeklyDays.includes(day)) {
						this.meeting.weeklyDays.splice(
							this.meeting.weeklyDays.findIndex((el) => el == day),
							1
						);
					} else {
						this.meeting.weeklyDays.push(day);
					}
					break;
				case "monthly":
					if (this.meeting.monthlyDays.includes(day)) {
						this.meeting.monthlyDays.splice(
							this.meeting.monthlyDays.findIndex((el) => el == day),
							1
						);
					} else {
						this.meeting.monthlyDays.push(day);
					}
					break;
				case "yearly":
					var date = day.hasOwnProperty("date") ? moment(day.date, "D/M/YYYY").format("MM-DD") : day;

					if (this.meeting.yearlyDays.includes(date)) {
						this.meeting.yearlyDays.splice(
							this.meeting.yearlyDays.findIndex((el) => el == date),
							1
						);
						if (fromList) {
							this.meeting.dateOfYear.selectedDates.splice(
								this.meeting.dateOfYear.selectedDates.findIndex(
									(el) => moment(el.date, "D/M/YYYY").format("MM-DD") == date
								),
								1
							);
						}
					} else {
						this.meeting.yearlyDays.push(date);
					}

					break;
			}
		},
		newStartDate(data) {
			this.configsDateOfFinish.limits = {
				min: data.date,
				max: "",
			};
			this.keyCalendarEndDate++;
		},
		completeLocation() {
			setTimeout(() => {
				$(`#dropdownLocation`).dropdown("toggle");
			}, 0);
		},
		checkNotification(type) {
			if (type == "open") {
				if (this.meeting.notification.value != "") {
					this.notificationCompleted = true;
				} else {
					this.notificationActive = false;
				}
			} else {
				this.notificationCompleted = false;
				this.notificationActive = true;
			}
		},
		checkInputs() {
			var notEmpty = false;

			if (this.meeting.title != "") {
				notEmpty = true;
			}

			if (this.usersSelected != []) {
				notEmpty = true;
			}

			if (this.meeting.description != "") {
				notEmpty = true;
			}

			return notEmpty;
		},
		openViewOnly() {
			this.$root.$emit("change_modal", "add_meeting_view_only", {
				crud: "edit",
				meetingEditId: this.data.meetingEditId,
				type: this.data.type,
				date: this.data.date,
				right: 1,
			});
		},
		updateUserRight(user) {
			if (!this.usersSelected) {
				this.usersSelected = [];
			}
			const foundItem = this.usersSelected.find((el) => el.id === user.id);

			if (foundItem) {
				foundItem.right = foundItem.right == "second" ? null : "second";
			} else {
				this.usersSelected.push({ ...user, right: "second" });
			}
		},
		updateTopic(value) {
			this.meetingTopic = JSON.parse(value);
		},
		clearTopic() {
			this.meetingTopic = [];
		},
		isCaptain(option) {
			if (!this.usersSelected) {
				return false;
			}

			const user = this.usersSelected.find((el) => el.id === option.id);
			return user ? user.right === "captain" : false;
		},
		isSecond(option) {
			if (!this.usersSelected) {
				return false;
			}

			const user = this.usersSelected.find((el) => el.id === option.id);
			return user ? user.right === "second" : false;
		},
	},
};

function arrayEquals(a, b) {
	return (
		(!a && !b) ||
		(Array.isArray(a) &&
			Array.isArray(b) &&
			a.length === b.length &&
			a.every((item) => b.includes(item)) &&
			b.every((item) => a.includes(item)))
	);
}

function valueEquals(a, b) {
	return (!a && !b) || a == b;
}
</script>
