<template>
	<div class="navbar-tbf row-space-tbf">
		<div class="space-left">
			<div class="back-btn" @click="goRoute(navbarBack)" v-if="navbarBack">
				<icon-arrow-back />
			</div>
		</div>
		<div class="content">
			<div class="title" :class="{ 'fw-500': $route.name == 'software-updates' || $route.name == 'software-updates-show' }">
				<h1>{{ navbarTitle }}</h1>
			</div>
			<div class="profile-items">
				<v-popover
					offset="5"
					trigger="hover"
					placement="bottom"
					popoverBaseClass="popover-tbf"
					:delay="{ show: 100, hide: 0 }"
					v-if="
						soft_organigram.cancel_at_period_end || soft_procedures.cancel_at_period_end || soft_objectives.cancel_at_period_end
					"
				>
					<router-link :to="{ name: 'settings-subscriptions' }" class="cancel-subscription-notification item-navbar">
						<div class="icon" v-if="$resize && $mq.below(780)"><icon-atention /></div>
						<div class="text" v-else>{{ $t("navbar.cancel_subscription_general_short") }}</div>
					</router-link>

					<template slot="popover">
						<div class="simple-text">{{ $t("navbar.cancel_subscription_general") }}</div>
					</template>
				</v-popover>

				<router-link
					:to="{ name: 'settings-subscriptions' }"
					class="grace-period-notification item-navbar"
					v-if="
						soft_organigram.status == 'grace_period' ||
							soft_procedures.status == 'grace_period' ||
							soft_objectives.status == 'grace_period'
					"
				>
					<div class="icon" v-if="$resize && $mq.below(780)"><icon-atention /></div>
					<div class="text" v-else>{{ $t("navbar.grace_period_notification") }}</div>
				</router-link>

				<v-popover
					offset="5"
					trigger="hover"
					placement="bottom"
					popoverBaseClass="popover-tbf"
					class="item-navbar"
					:delay="{ show: 100, hide: 0 }"
					v-if="showTimerTask"
				>
					<div class="timer-task-navbar" @click="openTaskModal()">
						<div class="record-icon"></div>
						<div class="text">{{ convertSecondsToHMS(timerTask.seconds) }}</div>
					</div>
					<template slot="popover">
						<div class="simple-text">Task: {{ timerTask.name }}</div>
					</template>
				</v-popover>
				<!-- <div class="next-webinar item-navbar" @click="showModal('webinar_tbf')">
					<div class="label">{{ $t("navbar.webinar") }}</div>
					<div class="date" v-if="$resize && $mq.above(779)">{{ getTextForWebinar() }}</div>
				</div> -->

				<template v-if="meetings.length">
					<v-popover
						ref="popoverMeetings"
						offset="5"
						trigger="click"
						placement="bottom"
						popoverBaseClass="popover-tbf"
						class="label"
						:delay="{ show: 100, hide: 0 }"
					>
						<div class="meetings-navbar green item-navbar">
							<div class="label">{{ $tc("navbar.more_meetings_today", meetings.length) }}</div>
							<div class="meeting-live" v-if="startedMeetings.length">
								<icon-record />
							</div>
						</div>

						<template slot="popover">
							<div class="list-meetings">
								<template v-if="startedMeetings.length">
									<div class="title">{{ $t("navbar.meetings_started") }}</div>
									<div class="item" v-for="meeting in startedMeetings" @click="closePopover()">
										<a class="text" :href="`/meeting/${meeting.session_link}`">
											{{ meeting.name }}
										</a>
										<div class="icon red">
											<icon-record />
										</div>
									</div>
								</template>

								<template v-if="awaitingMeetings.length">
									<div class="title">{{ $t("navbar.meetings_await") }}</div>
									<div class="item" v-for="meeting in awaitingMeetings" @click="closePopover()">
										<div
											class="text"
											@click="showModal('add_meeting_view_only', { meetingEditId: meeting.id, date: moment() })"
										>
											{{ meeting.name }}
										</div>
										<div class="icon yellow">
											<icon-bell />
										</div>
									</div>
								</template>

								<template v-if="nextMeetings.length">
									<div class="title">{{ $t("navbar.next_meetings") }}</div>
									<div class="item" v-for="meeting in nextMeetings" @click="closePopover()">
										<div
											class="text"
											@click="showModal('add_meeting_view_only', { meetingEditId: meeting.id, date: moment() })"
										>
											{{ meeting.name }}
										</div>
									</div>
								</template>
							</div>
						</template>
					</v-popover>
				</template>

				<div
					class="google-sync item-navbar"
					v-if="$auth.check() && !$auth.user().google_calendar && $auth.user().email == 'alex@tbf.ro'"
				>
					<v-popover
						offset="5"
						trigger="hover"
						placement="bottom"
						popoverBaseClass="popover-tbf"
						class="label"
						:delay="{ show: 100, hide: 0 }"
					>
						<button class="btn-navbar google-button" @click="$root.$emit('google_sign_in')">
							<img class="calendar-logo" src="/build/images/google-calendar.svg" />
							<img class="google-logo" src="/build/images/google-logo.svg" />
						</button>

						<template slot="popover">
							<div class="simple-text">{{ $t("sync_calendar.message_navbar") }}</div>
						</template>
					</v-popover>
				</div>

				<v-popover
					offset="5"
					trigger="hover"
					placement="bottom"
					popoverBaseClass="popover-tbf"
					class="mission-vision item-navbar"
					:delay="{ show: 100, hide: 0 }"
				>
					<button class="btn-navbar only-icon" @click="showModal('mission_vision')">
						<div class="icon"><icon-superman /></div>
					</button>

					<template slot="popover">
						<div class="simple-text">{{ $t("company.show_mission_vision") }}</div>
					</template>
				</v-popover>

				<v-popover
					offset="5"
					trigger="hover"
					placement="bottom"
					popoverBaseClass="popover-tbf"
					class="play-procedures item-navbar"
					:delay="{ show: 100, hide: 0 }"
					v-if="soft_procedures.is_active"
				>
					<button class="btn-navbar only-icon" @click="showModal('procedure_check', { userSlug: $auth.user().slug })">
						<div class="icon"><icon-learn /></div>
					</button>

					<template slot="popover">
						<div class="simple-text">{{ $t("navbar.procedures") }}</div>
					</template>
				</v-popover>

				<v-popover
					class="help item-navbar"
					offset="2"
					trigger="hover"
					placement="bottom"
					popoverBaseClass="popover-tbf"
					:delay="{ show: 0, hide: 0 }"
					v-if="helpData"
				>
					<a class="btn-navbar only-icon" :href="helpData.link" target="_blank">
						<div class="icon"><icon-question-circle /></div>
					</a>

					<template slot="popover">
						<div class="simple-text">{{ $t(`help_links.${helpData.type}`) }}</div>
					</template>
				</v-popover>

				<div
					class="item-navbar"
					v-if="$auth.ready() && $auth.user().instance && ['active', 'grace_period'].includes($auth.user().instance.status)"
				>
					<button class="btn-navbar only-icon" @click="showModal('notifications')">
						<div class="icon active" v-if="notifications.length"><icon-notifications-on /></div>
						<div class="icon" v-else><icon-notifications /></div>
					</button>
				</div>

				<div class="profile-navbar dropdown item-navbar" v-if="$auth.ready() && $auth.check()">
					<div class="has_notification" v-if="$auth.user().new_software_updates || $auth.user().new_software_wip"></div>

					<img
						:src="$auth.user().avatar"
						ref="dropdownUser"
						id="dropdownUser"
						data-toggle="dropdown"
						v-if="$auth.user().avatar"
					/>
					<div class="user-circle" ref="dropdownUser" id="dropdownUser" data-toggle="dropdown" v-else>
						<icon-user />
					</div>

					<div
						class="dropdown-menu dropdown-menu-right"
						id="dropdownApplications"
						aria-labelledby="dropdownApplications"
						v-if="$auth.ready()"
					>
						<div class="user-data">
							<div class="name">{{ $auth.user().first_name + " " + $auth.user().last_name }}</div>
							<div class="email">{{ $auth.user().email }}</div>
						</div>
						<div class="dropdown-item">
							<router-link :to="{ name: 'settings-profile' }">
								<div class="text">{{ $t("navbar.account") }}</div>
							</router-link>
						</div>
						<div class="dropdown-item">
							<router-link :to="{ name: 'announcements' }">
								<div class="text">{{ $t("navbar.announcements") }}</div>
							</router-link>
						</div>
						<div class="dropdown-item">
							<router-link :to="{ name: 'software-updates' }">
								<div class="text" :class="{ new: $auth.user().new_software_updates }">
									{{
										$tc("navbar.software_updates", $auth.user().new_software_updates, {
											count: $auth.user().new_software_updates,
										})
									}}
								</div>
							</router-link>
						</div>
						<div class="dropdown-item">
							<router-link :to="{ name: 'work-in-progress' }">
								<div class="text" :class="{ new: $auth.user().new_software_wip }">
									{{
										$tc("navbar.work_in_progress", $auth.user().new_software_wip, {
											count: $auth.user().new_software_wip,
										})
									}}
								</div>
							</router-link>
						</div>
						<div
							class="dropdown-item"
							v-if="$auth.user().instance && ['active', 'grace_period'].includes($auth.user().instance.status)"
						>
							<a href="https://help.tbf.ro" target="_blank">
								<div class="text">{{ $t("navbar.help_center") }}</div>
							</a>
						</div>
						<div class="dropdown-item" @click="showModal('contact')">
							<div class="text">{{ $t("navbar.contact") }}</div>
						</div>
						<div class="dropdown-item">
							<div v-if="$auth.impersonating()" @click="redirectToCentral">
								<div class="text">Impersonate: {{ $auth.user().first_name }} {{ $auth.user().last_name }}</div>
								<div class="text">
									Revino la Central
								</div>
							</div>

							<div class="text" @click="$auth.logout()" v-else>{{ $t("navbar.log_out") }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
	</div>
</template>

<script>
import IconBell from "../Icons/Bell";
import IconRecord from "../Icons/Record";
import IconDotsSquare from "../Icons/DotsSquare";
import IconUser from "../Icons/User";
import IconArrowBack from "../Icons/ArrowLong";
import IconAtention from "../Icons/Info";
import IconSuperman from "../Icons/Superman";
import IconQuestionCircle from "../Icons/QuestionCircle";
import IconNotifications from "../Icons/Notifications";
import IconNotificationsOn from "../Icons/NotificationsOn";
import IconLearn from "../Icons/LearnProcedures";

export default {
	data() {
		return {
			navbarTitle: "",
			navbarBack: "",
			idOrSlug: "",
			notifications: [],
			helpData: this.$store.getters["help_links/currentLink"](this.$route.name),
			meetings: [],
			nextMeetings: [],
			awaitingMeetings: [],
			startedMeetings: [],
			organigrame_fe_link: process.env.VUE_APP_PROCEDURES_FE_LINK,
			timerTask: {},
			showTimerTask: false,
			intervalTimer: {},
		};
	},
	components: {
		IconBell,
		IconRecord,
		IconDotsSquare,
		IconArrowBack,
		IconUser,
		IconAtention,
		IconSuperman,
		IconQuestionCircle,
		IconNotifications,
		IconNotificationsOn,
		IconLearn,
	},
	computed: {
		soft_procedures() {
			return this.$store.getters["applications/getApplication"]("Proceduri");
		},
		soft_organigram() {
			return this.$store.getters["applications/getApplication"]("Organigrama");
		},
		soft_objectives() {
			return this.$store.getters["applications/getApplication"]("Obiective");
		},
	},
	watch: {
		$route(to, from) {
			this.helpData = this.$store.getters["help_links/currentLink"](to.name);
		},
	},
	async mounted() {
		this.$auth.load().then(() => {
			if (this.$auth.check() && this.$cookies.get("websocket_notifications") != "start") {
				this.initWebSocket();
			}
		});

		this.$root.$on("navbar_title", (title, back = false, description = false, idOrSlug = false) => {
			this.navbarTitle = title;
			this.navbarBack = back;
			this.idOrSlug = idOrSlug;
		});

		this.$root.$on("clear_notifications", () => {
			this.notifications = [];
		});

		this.$root.$on("refreshTimer", () => {
			this.getTimerTask();
		});

		await this.getTimerTask();
		await this.getTodayMeetings();
	},
	methods: {
		initWebSocket() {
			this.socket = new WebSocket(process.env.VUE_APP_WSS_LINK_NOTIFICATION);

			this.socket.onopen = (event) => {
				this.$cookies.set("websocket_notifications", "start");

				// check in to websocket
				this.socket.send(
					JSON.stringify({
						user_id: this.$auth.user().id,
						type: "notification",
						soft: "organigram",
					})
				);
			};

			// when receiving a message
			this.socket.onmessage = ({ data }) => {
				const parsedData = JSON.parse(data);

				this.notifications.unshift(parsedData);
				this.$root.$emit("updateNotificationsList", parsedData);

				// trebuie de aici facut push la notificare
			};

			this.socket.onerror = (error) => {
				console.log(`Websocket error`);
			};

			this.socket.onclose = (event) => {
				this.$cookies.set("websocket_notifications", "close");

				if (event.wasClean) {
					console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
				} else {
					// e.g. server process killed or network down
					// event.code is usually 1006 in this case
					console.log("[close] Connection died");

					setTimeout(() => {
						this.initWebSocket();
					}, 600000);
				}
			};
		},
		goRoute(route) {
			this.idOrSlug ? this.$router.push({ name: route, params: { slug: this.idOrSlug } }) : this.$router.push({ name: route });
		},
		showModal(type, data = false, itemId = false) {
			this.$root.$emit("open_modal", type, data, itemId);
		},
		getTextForWebinar() {
			let date = "";
			let currentHour = moment(
				moment()
					.tz("Europe/Bucharest")
					.format("YYYY-MM-DD HH:mm")
			);
			let webinarEndHour = moment("11:00", "HH:mm").tz("Europe/Bucharest");

			var webinarStartHour = moment
				.tz("Europe/Bucharest")
				.hours(10)
				.startOf("hour");
			var localtz = moment.tz.guess();
			var dateClone = webinarStartHour.clone().tz(localtz);
			var formatDate = moment(dateClone).format("HH:mm");

			if (moment().isoWeekday() == 2 && currentHour.isAfter(webinarEndHour)) {
				date = `${this.$t("calendar.long_days.tuesday")} ${this.$t("webinar_tbf.time", { hour: formatDate })}`;
			}
			// else if(moment().isoWeekday() == 4 && currentHour.isAfter(webinarEndHour)) {
			// 	date = `${this.$t('calendar.long_days.tuesday')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
			// }
			else if (moment().isoWeekday() == 2 && currentHour.isBefore(webinarEndHour)) {
				date = `${this.$t("webinar_tbf.today")} ${this.$t("webinar_tbf.time", { hour: formatDate })}`;
			} else if (moment().isoWeekday() == 1) {
				date = `${this.$t("webinar_tbf.tomorrow")} ${this.$t("webinar_tbf.time", { hour: formatDate })}`;
			} else if (moment().isoWeekday() > 2) {
				date = `${this.$t("calendar.long_days.tuesday")} ${this.$t("webinar_tbf.time", { hour: formatDate })}`;
			}
			// else if(moment().isoWeekday() > 2 && moment().isoWeekday() < 4) {
			// 	date = `${this.$t('calendar.long_days.thursday')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
			// }

			return date;
		},
		startMeeting(id) {
			// axios.post(`/meeting-session/${id}/store`)
			// .then(() => {
			// })
		},
		getTodayMeetings() {
			axios.get(`/instance/${this.$auth.user().master_instance_id}/today-sessions`).then(({ data }) => {
				this.meetings = data.data;
				this.startedMeetings = data.data.filter((el) => el.status == "ongoing");
				this.nextMeetings = data.data.filter((el) => el.status != "ongoing" && moment() < moment(`${el.date} ${el.start_time}`));
				this.awaitingMeetings = data.data.filter(
					(el) => el.status != "ongoing" && moment() > moment(`${el.date} ${el.start_time}`)
				);
			});
		},
		closePopover() {
			const popover = this.$refs.popoverMeetings;
			popover.hide();
		},
		redirectToCentral() {
			window.location.replace(`${process.env.VUE_APP_CENTRAL_FE_LINK}/dashboard`);
		},
		async getTimerTask() {
			this.showTimerTask = false;
			clearInterval(this.intervalTimer);

			await axios.get("/user/last-task-timer").then(({ data }) => {
				if (data.data) {
					this.timerTask = data.data;

					this.intervalTimer = setInterval(() => {
						this.timerTask.seconds += 1;
					}, 1000);

					setTimeout(() => {
						this.showTimerTask = true;
					}, 200);
				}
			});
		},

		convertSecondsToHMS(seconds) {
			const hours = Math.floor(seconds / 3600);
			const minutes = Math.floor((seconds % 3600) / 60);
			const secondsRemaining = seconds % 60;
			const formattedHours = hours.toString().padStart(2, "0");
			const formattedMinutes = minutes.toString().padStart(2, "0");
			const formattedSeconds = secondsRemaining.toString().padStart(2, "0");
			return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
		},
		openTaskModal() {
			if (["dashboard", "dashboard_user"].includes(this.$route.name)) {
				this.$root.$emit("open_modal", "add_task", {
					crud: "edit",
					taskEditId: this.timerTask.task_id,
					date: this.timerTask.date,
				});
			} else {
				this.$router.push({
					name: "dashboard",
					query: { open_task: this.timerTask.task_id, date: this.timerTask.date },
				});
			}
		},
	},
};
</script>
