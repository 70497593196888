<!-- @format -->

<template>
	<div class="modal-tbf modal-full-page modal_evaluate_user">
		<div class="container-modal opacity-page show">
			<div class="header-modal">
				<div class="image">
					<img :src="user.avatar" v-if="user.avatar" />
					<div class="user-circle" v-else>
						<icon-user />
					</div>
				</div>
				<div class="title">
					<div class="name">{{ $t("assessments.title") }} {{ `${user.first_name} ${user.last_name ? user.last_name : ""}` }}</div>
				</div>
				<div class="actions">
					<button class="btn-tbf white only-icon close-btn" @click="backRoute"><icon-close class="icon-close" /></button>
				</div>
			</div>

			<template v-if="loadedList">
				<div class="data-list users" v-if="responsibilities.length">
					<div class="title">
						{{ $t("user-details.responsibilities") }}
					</div>
					<div class="row-space-tbf header-list">
						<div class="content">
							<div class="column-filter column-user">
								<div class="text">{{ $t("general.name") }}</div>
							</div>
							<div v-if="$resize && $mq.above(700)" class="column-filter column-total-sum">
								<div class="text">{{ $t("report_users.total_responsibilities") }}</div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-filter column-total-sum">
								<div class="text">{{ $t("report_users.total_target") }}</div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-filter column-total-sum">
								<div class="text">{{ $t("report_users.total_unrealized") }}</div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-filter column-total-sum">
								<div class="text">{{ $t("report_users.total_unrealized_special_reasons") }}</div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-filter column-total-sum">
								<div class="text">{{ $t("report_users.total_accomplished") }}</div>
							</div>
						</div>
					</div>

					<div class="scrollable">
						<template v-for="entry in responsibilities">
							<div class="row-space-tbf row-list-item" :key="`entryItem${entry.id}`">
								<div class="content">
									<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
									<div class="column-user" :class="[entry.progress_type ? 'column-name-desc' : 'column-name-title']">
										<div class="text">
											<v-popover
												offset="5"
												trigger="hover"
												placement="bottom"
												popoverBaseClass="popover-tbf"
												:delay="{ show: 200, hide: 0 }"
											>
												<div class="name">{{ entry.name }}</div>
												<template slot="popover">
													<div class="simple-text">{{ entry.name }}</div>
												</template>
											</v-popover>
											<div class="description" v-if="entry.progress_type">
												{{
													$t("responsibility.progress_type_text." + entry.progress_type, {
														to: parseFloat(entry.target),
														unit: entry.unity,
													})
												}}
											</div>
										</div>
									</div>
									<div v-if="$resize && $mq.above(700)" class="column-simple-text column-total-sum">
										<div class="text">{{ parseFloat(parseFloat(entry.total_logs).toFixed(2)) }}</div>
									</div>
									<div v-if="$resize && $mq.above(501)" class="column-simple-text column-total-sum">
										<div class="text">{{ parseFloat(parseFloat(entry.total_target).toFixed(2)) }}</div>
									</div>
									<div v-if="$resize && $mq.above(501)" class="column-simple-text column-total-sum">
										<div class="text">{{ parseFloat(parseFloat(entry.total_logs_unrealized).toFixed(2)) }}</div>
									</div>
									<div v-if="$resize && $mq.above(501)" class="column-simple-text column-total-sum">
										<div class="text">{{ parseFloat(parseFloat(entry.total_logs_special_reason).toFixed(2)) }}</div>
									</div>
									<div
										v-if="$resize && $mq.above(501)"
										class="column-simple-text column-total-sum"
										:class="[checkClassProgress(entry)]"
									>
										<div class="text">
											{{
												entry.total_value_logs == "no_evaluations"
													? $t("report_users.no_evaluations")
													: parseFloat(parseFloat(entry.total_value_logs).toFixed(2))
											}}
										</div>
									</div>
								</div>
							</div>
						</template>
					</div>
				</div>
			</template>
			<div class="form-group no-padding">
				<div class="form">
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t("assessments.stars") }}</label>
						</div>
						<star-rating v-model:rating="evaluation.stars" :show-rating="false" :star-size="25" :increment="0.5"> </star-rating>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t("assessments.good_things") }}*</label>
						</div>
						<div class="input-box" v-bind:class="{ has_error: $v.evaluation.good_things.$error }">
							<div class="icon-right" v-if="evaluation.good_things != ''" @click.stop="evaluation.good_things = ''">
								<icon-close class="icon-clear" />
							</div>
							<textarea v-model="evaluation.good_things" class="input-text" name="" id="" cols="30" rows="3"></textarea>
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t("assessments.what_to_change") }}*</label>
						</div>
						<div class="input-box" v-bind:class="{ has_error: $v.evaluation.what_to_change.$error }">
							<div class="icon-right" v-if="evaluation.what_to_change != ''" @click.stop="evaluation.what_to_change = ''">
								<icon-close class="icon-clear" />
							</div>
							<textarea v-model="evaluation.what_to_change" class="input-text" name="" id="" cols="30" rows="3"></textarea>
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t("responsibility.requires_proof_checkbox") }}</label>
						</div>
						<div class="w-flex">
							<div class="input-box pad-10" v-model="hasFile" onclick="document.getElementById('fileUploaded').click();">
								<icon-upload class="icon-upload" />
								<label class="placeholder" v-if="!fileUpload">{{ $t("create-user.add-file") }}</label>
								<div class="text" v-else>{{ fileUpload ? fileUpload.name : "" }}</div>
								<input type="file" id="fileUploaded" style="display:none" v-on:change="onFileChange" />
							</div>
						</div>

						<div v-if="fileError" class="error-msg custom-file-error">{{ fileError }}</div>

						<div class="selected-files">
							<div v-for="(file, index) in savedFiles" class="file-item">
								<span class="name">
									<icon-file class="icon-file" />
									<a :href="file.file_path" target="_blank" v-if="file.file_path">{{ file.filename }} </a>
									<div v-else>{{ file.filename }}</div>
									<button class="remove" @click="deleteFile(file, index)"><icon-close /></button>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form-submit">
				<div class="error-msg" v-show="error_message != ''">{{ error_message }}</div>
				<button class="btn-tbf grey" @click="backRoute()">
					<span class="text">{{ $t("general.cancel") }}</span>
				</button>
				<button id="buttonCreate" class="btn-tbf blue" @click="saveAction()">
					<div class="loader"></div>
					<span class="text">{{ $t("assessments.add") }}</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import StarRating from "vue-star-rating";
import IconClose from "../Icons/Close";
import IconUser from "../Icons/User";
import IconUpload from "../Icons/Upload";
import IconFile from "../Icons/File";

export default {
	components: {
		StarRating,
		IconClose,
		IconUser,
		IconUpload,
		IconFile,
	},
	props: {
		data: Object,
	},
	data() {
		return {
			loadedList: false,
			evaluation: {
				good_things: "",
				what_to_change: "",
				stars: 5,
			},
			error_message: "",
			user: {},
			fileUpload: "",
			savedFiles: [],
			fileError: "",
			hasFile: "",
			responsibilities: [],
		};
	},
	mounted() {
		this.user = this.data.user;
		this.getResponsibilities();
	},
	validations: {
		evaluation: {
			good_things: { required },
			stars: { required },
			what_to_change: { required },
		},
	},
	methods: {
		saveAction() {
			var buttonName = `buttonCreate`;
			var btnSubmit = document.getElementById(buttonName);
			var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`);
			var btnSubmitText = document.querySelector(`#${buttonName} .text`);
			btnSubmit.disabled = true;

			btnSubmit.classList.add("loading");
			btnSubmitLoader.classList.add("onProgress");
			btnSubmitText.innerHTML = this.$t("btn-submit.loading");
			this.error_message = "";
			this.$v.$touch();
			if (!this.$v.$invalid) {
				this.createEvaluation(this.evaluation, btnSubmit, btnSubmitText, btnSubmitLoader);
			} else {
				btnSubmitLoader.classList.add("finish");
				setTimeout(() => {
					btnSubmitText.innerHTML = this.$t("btn-submit.error");
					btnSubmit.classList.add("error");
					btnSubmitLoader.classList.remove("onProgress", "finish");
					btnSubmit.classList.remove("loading");
					setTimeout(() => {
						btnSubmit.classList.remove("error");
						btnSubmitText.innerHTML = this.$t("general.add");
						btnSubmit.disabled = false;
					}, 1000);
				}, 300);
			}
		},
		createEvaluation(objData, btnSubmit, btnSubmitText, btnSubmitLoader) {
			let objectData = new FormData();
			objectData.append("user_id", this.user.id);
			objectData.append("good_things", objData.good_things);
			objectData.append("what_to_change", objData.what_to_change);
			objectData.append("stars", objData.stars);

			for (var i = 0; i < this.savedFiles.length; i++) {
				if (this.savedFiles[i]) {
					let fileItem = this.savedFiles[i];

					if (fileItem.file) {
						objectData.append("files[" + i + "][file]", fileItem.file);
						if (fileItem.id) {
							objectData.append("files[" + i + "][id]", fileItem.id);
						}
					}
				}
			}

			axios
				.post("/user-evaluations/store", objectData)
				.then(({ data }) => {
					btnSubmitLoader.classList.add("finish");
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.success");
						btnSubmit.classList.add("completed");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						this.$v.$reset();
						setTimeout(() => {
							this.backRoute();
							btnSubmit.classList.remove("completed");
							btnSubmitText.innerHTML = this.$t("general.add");
						}, 1000);
					}, 300);
				})
				.catch((error) => {
					btnSubmitLoader.classList.add("finish");
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.error");
						btnSubmit.classList.add("error");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							btnSubmit.classList.remove("error");
							btnSubmitText.innerHTML = this.$t("general.add");
							btnSubmit.disabled = false;
							if (error.response.status == 500) {
								this.error_message = this.$t("error.500");
							}
						}, 1000);
					}, 300);
				});
		},
		backRoute() {
			this.$emit("close_modal");
			this.$root.$emit("refreshEvaluationsIndex");
		},
		onFileChange(e) {
			this.fileError = "";

			var files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;

			this.fileUpload = files[0];
			this.hasFile = "true";

			this.addFile();
		},
		addFile() {
			if (this.fileUpload) {
				if (this.fileUpload.size <= 24999999) {
					let objectFile = {
						file: this.fileUpload,
						filename: this.fileUpload.name,
					};

					this.savedFiles.push(objectFile);
					this.fileUpload = "";
				} else {
					this.fileError = this.$t("validator.file_size");
				}
			}
		},
		deleteFile(file, index) {
			if (file.id) {
				this.awaitingFilesForRemove.push(file.id);
			}
			this.savedFiles.splice(index, 1);

			this.savedFiles.length == 0 ? (this.hasFile = "") : "";
		},
		getResponsibilities() {
			axios
				.get(`/reports/${this.data.user.slug}/user-responsibilities`, { params: { type: "evaluation" } })
				.then(({ data }) => {
					this.responsibilities = data.data;
				})
				.finally(() => {
					this.loadedList = true;
				});
		},
		checkClassProgress(data) {
			if (data.type == "check") {
				if (data.total_value_logs >= data.total_target) {
					return "green";
				}
			} else if (data.type == "value") {
				if (data.progress_type == "grow") {
					if (data.total_value_logs >= data.total_target) {
						return "green";
					}
				} else {
					if (data.total_value_logs != "no_evaluations") {
						if (data.total_value_logs <= data.total_target) {
							return "green";
						}
					} else {
						return "red";
					}
				}
			}

			return "red";
		},
	},
};
</script>
