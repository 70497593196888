var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal_info submodal-create-update"},[(_vm.loaded)?_c('div',{staticClass:"container-content-modal"},[_c('div',{staticClass:"header-submodal with-btn"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("key_results.update"))+" "+_vm._s(_vm.moment().format("DD MMM")))]),_c('div',{staticClass:"button-close"},[_c('button',{staticClass:"btn-tbf white only-icon close-btn",on:{"click":_vm.closeModal}},[_c('icon-close',{staticClass:"icon-close"})],1)])]),_c('div',{staticClass:"description-modal text-left",domProps:{"innerHTML":_vm._s(
				_vm.$t('key_results.create-updates-description-detailed', {
					in_the_past: _vm.diffDaysFromCreate(_vm.keyResult.last_key_result_log ? _vm.keyResult.last_key_result_log.created_at : _vm.keyResult.start_date),
					last_update_value:
						((_vm.keyResult.last_key_result_log ? _vm.keyResult.last_key_result_log.value : null) % 1 != 0
							? _vm.$options.filters.numeral(_vm.keyResult.last_key_result_log ? _vm.keyResult.last_key_result_log.value : null, '0,0.00')
							: _vm.$options.filters.numeral(_vm.keyResult.last_key_result_log ? _vm.keyResult.last_key_result_log.value : null, '0,0')) + _vm.keyResult.unity,
					text: _vm.$t('show_user.key_result_progress_type.' + _vm.keyResult.progress_type, {
						to: _vm.keyResult.target % 1 != 0 ? _vm.$options.filters.numeral(_vm.keyResult.target, '0,0.00') : _vm.$options.filters.numeral(_vm.keyResult.target, '0,0'),
						unit: _vm.keyResult.unity,
						from: _vm.keyResult.start_value % 1 != 0 ? _vm.$options.filters.numeral(_vm.keyResult.start_value, '0,0.00') : _vm.$options.filters.numeral(_vm.keyResult.start_value, '0,0'),
					}).toLowerCase(),
				})
			)}}),_c('div',{staticClass:"form-section box-update-key-result"},[_c('div',{staticClass:"input-group update-kr",class:{ has_error: _vm.$v.update_key_result.value.$error, complete: _vm.update_key_result.value != '' && !_vm.$v.update_key_result.value.$error }},[_c('div',{staticClass:"label-input"},[_c('label',[_vm._v(_vm._s(_vm.$t("key_results.new-value"))+" ("+_vm._s(_vm.keyResult.unity)+")")])]),_c('div',{staticClass:"input-box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.update_key_result.value),expression:"update_key_result.value"}],ref:"first_input",staticClass:"input-update-kr",attrs:{"type":"number","placeholder":_vm.$t('key_results.new-value') + ' (' + _vm.keyResult.unity + ')',"min":"0"},domProps:{"value":(_vm.update_key_result.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.update_key_result, "value", $event.target.value)}}}),_c('div',{staticClass:"calculate-new-percent"},[_c('img',{attrs:{"src":"/build/images/new-percent.svg"}}),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.diffPercent(_vm.update_key_result.value)))])])])]),_c('div',{staticClass:"input-group update-kr",class:{ has_error: _vm.$v.update_key_result.description.$error, complete: _vm.update_key_result.description != '' && !_vm.$v.update_key_result.description.$error }},[_c('div',{staticClass:"label-input"},[_c('label',[_vm._v(_vm._s(_vm.$t("key_results.description")))])]),_c('div',{staticClass:"input-box"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.update_key_result.description),expression:"update_key_result.description"}],staticClass:"input-textarea",attrs:{"placeholder":_vm.$t('key_results.description-placeholder'),"rows":"3"},domProps:{"value":(_vm.update_key_result.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.update_key_result, "description", $event.target.value)}}})])]),_c('div',{staticClass:"input-group",class:{
					has_error: _vm.$v.update_key_result.degree_of_confidence.$error,
					complete: _vm.update_key_result.degree_of_confidence != '' && !_vm.$v.update_key_result.degree_of_confidence.$error,
				}},[_c('div',{staticClass:"label-input"},[_c('label',[_vm._v(_vm._s(_vm.$t("key_results.trust-grade")))])]),_c('div',{staticClass:"options-degree"},_vm._l((_vm.optionsDegreeConfidence),function(optionDegree){return _c('div',{staticClass:"option",class:{ selected: optionDegree == _vm.update_key_result.degree_of_confidence },on:{"click":function($event){_vm.update_key_result.degree_of_confidence = optionDegree}}},[_c('img',{staticClass:"active-icon",attrs:{"src":'/build/images/mood-trs-' + optionDegree + '.svg'}}),_c('img',{staticClass:"simple-icon",attrs:{"src":'/build/images/mood-' + optionDegree + '-bw.svg'}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t(("key_results.mood_type." + optionDegree))))])])}),0)])]),_c('div',{staticClass:"button-actions"},[_c('button',{staticClass:"btn-tbf grey center cancel",on:{"click":_vm.closeModal}},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("general.cancel")))])]),_c('button',{staticClass:"btn-tbf blue center submit",on:{"click":_vm.submitUpdate}},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("general.update")))])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }