<template>
	<div class="modal_info confirm-close">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/warning.svg" /></div>
			<div class="title">{{ $t("add_task.info_timer_attention") }}</div>
		</div>
		<div class="description-modal">{{ $t("add_task.info_timer_description", { taskName: data.taskName }) }}</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelClose">
				<div class="text">{{ $t("general.cancel") }}</div>
			</button>
			<button class="btn-tbf blue center" @click="$emit('submit')">
				<div class="loader"></div>
				<div class="text">{{ $t("add_task.info_timer_start_new_task") }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
export default {
	props: {
		data: Object,
	},
	data() {
		return {};
	},
	async mounted() {},
	methods: {
		cancelClose() {
			this.$emit("cancel_close");
		},
	},
};
</script>
