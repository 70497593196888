<template>
	<div class="main-template">
		<template v-if="loadedApplications">
			<sidebar v-if="$resize && $mq.above(781)" />
			<div class="container-tbf">
				<navbar />
				<div class="container-content">
					<slot></slot>
				</div>
				<sidebar-mobile v-if="$resize && $mq.below(780)" />
			</div>
			<notifications />

			<div class="overflow-modal" v-if="show_overflow">
				<div class="bg-modals" @click="closeModals()"></div>
				<modal-required-instance
					id="modal_required_instance"
					v-if="show_modal.required_instance"
					:data="data.required_instance"
					@close_modal="closeModal('required_instance')"
				/>
				<modal-change-subscription
					id="modal_change_subscription"
					v-if="show_modal.change_subscription"
					@close_modal="closeModal('change_subscription')"
				/>
				<modal-delete id="modal_delete" v-if="show_modal.delete" :data="data.delete" @close_modal="closeModal('delete')" />
				<modal-notifications id="modal_notifications" v-if="show_modal.notifications" @close_modal="closeModal('notifications')" />
				<modal-grace-period id="modal_payment_error" v-if="show_modal.payment_error" @close_modal="closeModal('payment_error')" />
				<modal-warning-limit-package
					id="modal_warning_limit_package"
					v-if="show_modal.warning_limit_package"
					@close_modal="closeModal('warning_limit_package')"
				/>
				<modal-settings-mobile
					id="modal_settings_mobile"
					v-if="show_modal.settings_mobile"
					@close_modal="closeModal('settings_mobile')"
				/>
				<modal-reset-password
					id="modal_reset_password"
					v-if="show_modal.reset_password"
					:data="data.reset_password"
					@close_modal="closeModal('reset_password')"
				/>
				<modal-contact id="modal_contact" v-if="show_modal.contact" @close_modal="closeModal('contact')" />
				<modal-webinar-tehnic id="modal_webinar_tbf" v-if="show_modal.webinar_tbf" @close_modal="closeModal('webinar_tbf')" />
				<modal-vacant-job
					id="modal_vacant_job"
					v-if="show_modal.vacant_job"
					:data="data.vacant_job"
					@close_modal="closeModal('vacant_job')"
				/>

				<modal-crud-responsibility
					id="modal_crud_responsibility"
					v-if="show_modal.crud_responsibility"
					:data="data.crud_responsibility"
					@close_modal="closeModal('crud_responsibility')"
				/>
				<modal-show-responsibility
					id="modal_show_responsibility"
					v-if="show_modal.show_responsibility"
					:data="data.show_responsibility"
					@close_modal="closeModal('show_responsibility')"
				/>
				<modal-show-task-key-result
					id="modal_show_task_key_result"
					v-if="show_modal.show_task_key_result"
					:data="data.show_task_key_result"
					@close_modal="closeModal('show_task_key_result')"
				/>
				<modal-report-user-responsibilities
					id="modal_report_user_responsibilities"
					v-if="show_modal.report_user_responsibilities"
					:data="data.report_user_responsibilities"
					@close_modal="closeModal('report_user_responsibilities')"
				/>
				<modal-confirm-archiving
					id="modal_confirm_archiving"
					v-if="show_modal.confirm_archiving"
					:data="data.confirm_archiving"
					@close_modal="closeModal('confirm_archiving')"
				/>
				<modal-calendar-list
					id="modal_calendar_list"
					v-if="show_modal.calendar_list"
					:data="data.calendar_list"
					@close_modal="closeModal('calendar_list')"
				/>
				<modal-show-evaluation
					id="modal_show_evaluation"
					v-if="show_modal.show_evaluation"
					:data="data.show_evaluation"
					@close_modal="closeModal('show_evaluation')"
				/>
				<modal-user-free-days
					id="modal_user_free_days"
					v-if="show_modal.user_free_days"
					:data="data.user_free_days"
					@close_modal="closeModal('user_free_days')"
				/>
				<modal-mission-vision
					id="modal_mission_vision"
					v-if="show_modal.mission_vision"
					@close_modal="closeModal('mission_vision')"
				/>
				<modal-vacant-job-from-user
					id="modal_vacant_job_from_user"
					:data="data.vacant_job_from_user"
					v-if="show_modal.vacant_job_from_user"
					@close_modal="closeModal('vacant_job_from_user')"
				/>
				<modal-clone id="modal_clone" :data="data.clone" v-if="show_modal.clone" @close_modal="closeModal('clone')" />
				<modal-add-promise id="modal_add_promise" v-if="show_modal.add_promise" @close_modal="closeModal('add_promise')" />
				<modal-evaluate-promise
					id="modal_evaluate_promise"
					:data="data.evaluate_promise"
					v-if="show_modal.evaluate_promise"
					@close_modal="closeModal('evaluate_promise')"
				/>
				<modal-congratulation-and-warning
					id="modal_congratulation_and_warning"
					v-if="show_modal.congratulation_and_warning"
					:data="data.congratulation_and_warning"
					@close_modal="closeModal('congratulation_and_warning')"
				/>
				<modal-evaluation
					id="modal_evaluation"
					:data="data.evaluation"
					v-if="show_modal.evaluation"
					@close_modal="closeModal('evaluation')"
				/>
				<modal-add-task
					id="modal_add_task"
					:data="data.add_task"
					v-if="show_modal.add_task"
					@close_modal="closeModal('add_task')"
				/>
				<modal-procedure-check
					id="modal_procedure_check"
					:data="data.procedure_check"
					v-if="show_modal.procedure_check"
					@close_modal="closeModal('procedure_check')"
				/>
				<modal-crud-day-off
					id="modal_crud_day_off"
					v-if="show_modal.crud_day_off"
					:data="data.crud_day_off"
					@close_modal="closeModal('crud_day_off')"
				/>
				<modal-show-day-off
					id="modal_show_day_off"
					v-if="show_modal.show_day_off"
					:data="data.show_day_off"
					@close_modal="closeModal('show_day_off')"
				/>
				<modal-responsibilities-unique
					id="modal_responsibilities_unique"
					v-if="show_modal.responsibilities_unique"
					:data="data.responsibilities_unique"
					@close_modal="closeModal('responsibilities_unique')"
				/>
				<modal-evaluate-user
					id="modal_evaluate_user"
					v-if="show_modal.evaluate_user"
					:data="data.evaluate_user"
					@close_modal="closeModal('evaluate_user')"
				/>
				<modal-create-link-on-project
					id="modal_create_link_on_project"
					v-if="show_modal.create_link_on_project"
					:data="data.create_link_on_project"
					@close_modal="closeModal('create_link_on_project')"
				/>
				<modal-add-meeting
					id="modal_add_meeting"
					v-if="show_modal.add_meeting"
					:data="data.add_meeting"
					@close_modal="closeModal('add_meeting')"
				/>
				<modal-choose-activity
					id="modal_choose_activity"
					v-if="show_modal.choose_activity"
					:data="data.choose_activity"
					@close_modal="closeModal('choose_activity')"
				/>
				<modal-add-meeting-view-only
					id="modal_add_meeting_view_only"
					v-if="show_modal.add_meeting_view_only"
					:data="data.add_meeting_view_only"
					@close_modal="closeModal('add_meeting_view_only')"
				/>
				<modal-meeting-summary
					id="modal_meeting_summary"
					v-if="show_modal.meeting_summary"
					:data="data.meeting_summary"
					@close_modal="closeModal('meeting_summary')"
				/>
				<modal-advanced-settings-project
					id="modal_advanced_settings_project"
					v-if="show_modal.advanced_settings_project"
					:data="data.advanced_settings_project"
					@close_modal="closeModal('advanced_settings_project')"
				/>
				<modal-view-responsibility
					id="modal_view_responsibility"
					v-if="show_modal.view_responsibility"
					:data="data.view_responsibility"
					@close_modal="closeModal('view_responsibility')"
				/>
			</div>
		</template>
		<div class="page-loader-on-start" v-else>
			<div class="loader-spin"></div>
		</div>
	</div>
</template>

<script>
import Sidebar from "../General/Sidebar.vue";
import Navbar from "../General/Navbar.vue";
import ModalRequiredInstance from "../Modals/RequiredInstance";
import ModalChangeSubscription from "../Modals/ChangeSubscription";
import ModalDelete from "../Modals/Delete";
import ModalNotifications from "../General/SidebarNotifications";
import ModalGracePeriod from "../Modals/GracePeriod";
import ModalWarningLimitPackage from "../Modals/WarningLimitPackage";
import SidebarMobile from "../General/SidebarMobile";
import ModalSettingsMobile from "../General/SidebarSettingsMobile";
import ModalResetPassword from "../Modals/ResetPassword";
import ModalContact from "../Modals/Contact";
import ModalCrudResponsibility from "../Modals/CrudResponsibility";
import ModalShowResponsibility from "../Modals/ShowResponsibility";
import ModalReportUserResponsibilities from "../Modals/ReportUserResponsibilities";
import ModalConfirmArchiving from "../Modals/ConfirmArchiving";
import ModalWebinarTehnic from "../Modals/WebinarTehnic";
import ModalVacantJob from "../Modals/VacantJob";
import ModalCalendarList from "../Modals/CalendarList";
import ModalShowEvaluation from "../Modals/ShowEvaluation";
import ModalUserFreeDays from "../Modals/UserFreeDays";
import ModalMissionVision from "../Modals/MissionVision";
import ModalVacantJobFromUser from "../Modals/VacantJobFromUser";
import ModalClone from "../Modals/Clone";
import ModalCongratulationAndWarning from "../Modals/CongratulationAndWarning";
import ModalEvaluation from "../Modals/Evaluation";
import ModalAddPromise from "../Modals/AddPromise";
import ModalEvaluatePromise from "../Modals/EvaluatePromise";
import ModalAddTask from "../Modals/NewAddTask";
import ModalProcedureCheck from "../Modals/ProcedureCheck";
import ModalShowTaskKeyResult from "../Modals/ShowTaskKeyResult";
import ModalCrudDayOff from "../Modals/CrudDayOff";
import ModalShowDayOff from "../Modals/ShowDayOff";
import ModalResponsibilitiesUnique from "../Modals/ResponsibilitiesUnique";
import ModalEvaluateUser from "../Modals/EvaluateUser";
import Notifications from "../General/Notifications";
import ModalAddMeeting from "../Modals/AddMeeting";
import ModalCreateLinkOnProject from "../Pages/Projects/Components/CreateLink";
import ModalChooseActivity from "../Modals/ChooseActivity";
import ModalAddMeetingViewOnly from "../Modals/AddMeetingViewOnly";
import ModalMeetingSummary from "../Modals/MeetingSummary";
import ModalAdvancedSettingsProject from "../Modals/AdvancedSettingsProject";
import ModalViewResponsibility from "../Modals/ViewResponsability";

export default {
	components: {
		Sidebar,
		SidebarMobile,
		Navbar,
		ModalRequiredInstance,
		ModalChangeSubscription,
		ModalNotifications,
		ModalDelete,
		ModalGracePeriod,
		ModalSettingsMobile,
		ModalWarningLimitPackage,
		ModalResetPassword,
		ModalContact,
		ModalCrudResponsibility,
		ModalShowResponsibility,
		ModalReportUserResponsibilities,
		ModalConfirmArchiving,
		ModalWebinarTehnic,
		ModalVacantJob,
		ModalCalendarList,
		ModalShowEvaluation,
		ModalUserFreeDays,
		ModalMissionVision,
		ModalVacantJobFromUser,
		ModalClone,
		ModalCongratulationAndWarning,
		ModalEvaluation,
		ModalAddPromise,
		ModalEvaluatePromise,
		ModalAddTask,
		ModalProcedureCheck,
		ModalShowTaskKeyResult,
		ModalCrudDayOff,
		ModalShowDayOff,
		ModalResponsibilitiesUnique,
		ModalEvaluateUser,
		Notifications,
		ModalAddMeeting,
		ModalCreateLinkOnProject,
		ModalChooseActivity,
		ModalAddMeetingViewOnly,
		ModalMeetingSummary,
		ModalAdvancedSettingsProject,
		ModalViewResponsibility,
	},
	data() {
		return {
			show_overflow: false,
			show_modal: {
				required_instance: false,
				role: false,
				delete: false,
				payment_error: false,
				change_subscription: false,
				settings_mobile: false,
				warning_limit_package: false,
				reset_password: false,
				contact: false,
				crud_responsibility: false,
				show_responsibility: false,
				report_user_responsibilities: false,
				confirm_archiving: false,
				webinar_tbf: false,
				vacant_job: false,
				calendar_list: false,
				show_evaluation: false,
				user_free_days: false,
				mission_vision: false,
				vacant_job_from_user: false,
				clone: false,
				add_promise: false,
				evaluate_promise: false,
				congratulation_and_warning: false,
				evaluation: false,
				add_task: false,
				procedure_check: false,
				show_task_key_result: false,
				crud_day_off: false,
				show_day_off: false,
				responsibilities_unique: false,
				evaluate_user: false,
				add_meeting: false,
				create_link_on_project: false,
				choose_activity: false,
				add_meeting_view_only: false,
				meeting_summary: false,
				advanced_settings_project: false,
				view_responsibility: false,
			},
			data: {
				required_instance: {},
				delete: {},
				reset_password: {},
				crud_responsibility: {},
				show_responsibility: {},
				report_user_responsibilities: {},
				confirm_archiving: {},
				vacant_job: {},
				calendar_list: {},
				show_evaluation: {},
				user_free_days: {},
				vacant_job_from_user: {},
				clone: {},
				evaluate_promise: {},
				congratulation_and_warning: {},
				evaluation: {},
				add_task: {},
				procedure_check: {},
				show_task_key_result: {},
				crud_day_off: {},
				show_day_off: {},
				responsibilities_unique: {},
				evaluate_user: {},
				add_meeting: {},
				create_link_on_project: {},
				choose_activity: {},
				add_meeting_view_only: {},
				meeting_summary: {},
				advanced_settings_project: {},
				view_responsibility: {},
			},
			active_modal: "",
			loadedApplications: false,
		};
	},
	computed: {
		soft_procedures() {
			return this.$store.getters["applications/getApplication"]("Proceduri");
		},
		soft_objectives() {
			return this.$store.getters["applications/getApplication"]("Obiective");
		},
		soft_organigram() {
			return this.$store.getters["applications/getApplication"]("Organigrama");
		},
	},
	mounted() {
		if (this.$store.state.applications.applications.length) {
			this.initItercom();
			this.loadedApplications = true;

			if (
				(this.soft_organigram.status == "grace_period" ||
					this.soft_procedures.status == "grace_period" ||
					this.soft_objectives.status == "grace_period") &&
				!this.$cookies.get("grace_period_modal")
			) {
				this.$cookies.set("grace_period_modal", true, "1d");
				this.openModalFunction("payment_error");
			}
		} else {
			var refreshIntervalApps = setInterval(() => {
				if (this.$auth.check()) {
					if (this.$store.state.applications.applications.length) {
						clearInterval(refreshIntervalApps);
						this.initItercom();
						this.loadedApplications = true;
						if (
							(this.soft_organigram.status == "grace_period" ||
								this.soft_procedures.status == "grace_period" ||
								this.soft_objectives.status == "grace_period") &&
							!this.$cookies.get("grace_period_modal")
						) {
							this.$cookies.set("grace_period_modal", true, "1d");
							this.openModalFunction("payment_error");
						}
					}
				}
			}, 50);
		}

		this.$root.$on("open_modal", (type, data = false) => {
			this.openModalFunction(type, data);
		});

		this.$root.$on("change_modal", (type, data = false) => {
			this.data[type] = data ? data : {};
			$("#modal_" + this.active_modal).removeClass("active");
			setTimeout(() => {
				this.show_modal[this.active_modal] = false;
				setTimeout(() => {
					this.show_modal[type] = true;
					this.active_modal = type;
					setTimeout(() => {
						$("#modal_" + type).addClass("active");
					}, 0);
				}, 0);
			}, 200);
		});

		this.checkLanguage();
	},
	methods: {
		closeModals() {
			var arrayModalsNeedVerification = ["crud_responsibility", "calendar_list", "add_task", "crud_day_off", "add_meeting"];
			if (arrayModalsNeedVerification.includes(this.active_modal)) {
				this.$root.$emit("confirm_close");
			} else {
				this.closeModal();
			}
		},
		closeModal(type = false) {
			var target_modal = type ? type : this.active_modal;
			$("#modal_" + target_modal).removeClass("active");

			setTimeout(() => {
				this.show_modal[target_modal] = false;
				$(".overflow-modal").removeClass("show");
				setTimeout(() => {
					this.show_overflow = false;
				}, 200);
			}, 200);
		},
		initItercom() {
			var apps = {
				Obiective: false,
				Proceduri: false,
				Cursuri: this.$auth.user().instance && this.$auth.user().instance.id <= 1340 ? true : false,
				Organigrama: false,
			};

			this.$store.state.applications.applications.map((el) => {
				if (el.application) {
					apps[el.application.name] = el.is_active;
				}
			});

			Intercom("boot", {
				app_id: "tk8wk42t",
				name: this.$auth.user().first_name + " " + this.$auth.user().last_name,
				email: this.$auth.user().email,
				package_id: this.$auth.user().instance ? this.$auth.user().instance.package_id : "",
				user_hash: this.$auth.user().intercom_token,
				created_at: null,
				hide_default_launcher: this.$resize && this.$mq.above(780) ? false : true,
				Obiective: apps["Obiective"],
				Proceduri: apps["Proceduri"],
				Cursuri: apps["Cursuri"],
				Organigrama: apps["Organigrama"],
			});
		},
		checkLanguage() {
			if (this.$auth.check()) {
				this.$i18n.locale = this.$auth.user().language;
				this.$cookies.set("language", this.$auth.user().language, "1m");
			} else if (this.$cookies.get("language")) {
				this.$i18n.locale = this.$cookies.get("language");
			}
		},
		openModalFunction(type, data = false) {
			this.active_modal = type;
			this.data[type] = data ? data : {};

			setTimeout(() => {
				this.show_overflow = true;
				this.show_modal[type] = true;
				setTimeout(() => {
					$(".overflow-modal").addClass("show");
					$("#modal_" + type).addClass("active");
				}, 0);
			}, 0);
		},
	},
};
</script>

<style lang="scss">
@import "../../styles/templates/main-template.scss";
</style>
