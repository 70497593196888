<template>
	<div class="modal_info submodal-create-update">
		<div class="container-content-modal" v-if="loaded">
			<div class="header-submodal with-btn">
				<div class="title">{{ $t("key_results.update") }} {{ moment().format("DD MMM") }}</div>
				<div class="button-close">
					<button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
				</div>
			</div>

			<div
				class="description-modal text-left"
				v-html="
					$t('key_results.create-updates-description-detailed', {
						in_the_past: diffDaysFromCreate(keyResult.last_key_result_log ? keyResult.last_key_result_log.created_at : keyResult.start_date),
						last_update_value:
							((keyResult.last_key_result_log ? keyResult.last_key_result_log.value : null) % 1 != 0
								? $options.filters.numeral(keyResult.last_key_result_log ? keyResult.last_key_result_log.value : null, '0,0.00')
								: $options.filters.numeral(keyResult.last_key_result_log ? keyResult.last_key_result_log.value : null, '0,0')) + keyResult.unity,
						text: $t('show_user.key_result_progress_type.' + keyResult.progress_type, {
							to: keyResult.target % 1 != 0 ? $options.filters.numeral(keyResult.target, '0,0.00') : $options.filters.numeral(keyResult.target, '0,0'),
							unit: keyResult.unity,
							from: keyResult.start_value % 1 != 0 ? $options.filters.numeral(keyResult.start_value, '0,0.00') : $options.filters.numeral(keyResult.start_value, '0,0'),
						}).toLowerCase(),
					})
				"
			></div>

			<div class="form-section box-update-key-result">
				<div class="input-group update-kr" v-bind:class="{ has_error: $v.update_key_result.value.$error, complete: update_key_result.value != '' && !$v.update_key_result.value.$error }">
					<div class="label-input">
						<label>{{ $t("key_results.new-value") }} ({{ keyResult.unity }})</label>
					</div>
					<div class="input-box">
						<input
							type="number"
							:placeholder="$t('key_results.new-value') + ' (' + keyResult.unity + ')'"
							class="input-update-kr"
							min="0"
							v-model="update_key_result.value"
							ref="first_input"
						/>
						<div class="calculate-new-percent">
							<img src="/build/images/new-percent.svg" />
							<span class="value">{{ diffPercent(update_key_result.value) }}</span>
						</div>
					</div>
				</div>

				<div
					class="input-group update-kr"
					v-bind:class="{ has_error: $v.update_key_result.description.$error, complete: update_key_result.description != '' && !$v.update_key_result.description.$error }"
				>
					<div class="label-input">
						<label>{{ $t("key_results.description") }}</label>
					</div>
					<div class="input-box">
						<textarea class="input-textarea" :placeholder="$t('key_results.description-placeholder')" v-model="update_key_result.description" rows="3"></textarea>
					</div>
				</div>

				<div
					class="input-group"
					v-bind:class="{
						has_error: $v.update_key_result.degree_of_confidence.$error,
						complete: update_key_result.degree_of_confidence != '' && !$v.update_key_result.degree_of_confidence.$error,
					}"
				>
					<div class="label-input">
						<label>{{ $t("key_results.trust-grade") }}</label>
					</div>
					<div class="options-degree">
						<div
							class="option"
							v-for="optionDegree in optionsDegreeConfidence"
							@click="update_key_result.degree_of_confidence = optionDegree"
							v-bind:class="{ selected: optionDegree == update_key_result.degree_of_confidence }"
						>
							<img :src="'/build/images/mood-trs-' + optionDegree + '.svg'" class="active-icon" />
							<img :src="'/build/images/mood-' + optionDegree + '-bw.svg'" class="simple-icon" />
							<div class="name">{{ $t(`key_results.mood_type.${optionDegree}`) }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="button-actions">
				<button class="btn-tbf grey center cancel" @click="closeModal">
					<div class="text">{{ $t("general.cancel") }}</div>
				</button>
				<button class="btn-tbf blue center submit" @click="submitUpdate">
					<div class="text">{{ $t("general.update") }}</div>
				</button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import IconPlus from "../Icons/Plus";
import IconArrow from "../Icons/Arrow";
import IconClose from "../Icons/Close";
import IconCheck from "../Icons/Check";
import IconInfo from "../Icons/Info";
import IconCircleCheck from "../Icons/CircleCheck";
import { required, decimal } from "vuelidate/lib/validators";

export default {
	data() {
		return {
			keyResult: {},
			loaded: false,
			update_key_result: {
				value: "",
				description: "",
				degree_of_confidence: "",
			},
			optionsDegreeConfidence: ["uncertain", "not_very_sure", "sure"],
		};
	},
	components: {
		IconPlus,
		IconArrow,
		IconClose,
		IconCheck,
		IconInfo,
		IconCircleCheck,
	},
	validations: {
		update_key_result: {
			value: { required, decimal },
			description: { required },
			degree_of_confidence: { required },
		},
	},
	props: {
		data: Object,
	},
	async mounted() {
		this.keyResult = this.data.keyResult;
		this.loaded = true;
	},
	methods: {
		closeModal() {
			this.$emit("close_modal");
		},
		diffPercent(value) {
			if (value) {
				var percent = 0;
				var diffPercentVal = 0;
				switch (this.keyResult.progress_type) {
					case "grow":
						percent = 100 - (this.keyResult.target - value) / ((this.keyResult.target - this.keyResult.start_value) / 100);
						diffPercentVal = percent.toFixed(2) - this.keyResult.percent;
						diffPercentVal = diffPercentVal.toFixed(2);
						return diffPercentVal < 0 ? diffPercentVal + "%" : "+" + diffPercentVal + "%";
					case "decrease":
						percent = 100 - (value - this.keyResult.target) / ((this.keyResult.start_value - this.keyResult.target) / 100);
						diffPercentVal = percent.toFixed(2) - this.keyResult.percent;
						diffPercentVal = diffPercentVal.toFixed(2);
						return diffPercentVal < 0 ? diffPercentVal + "%" : "+" + diffPercentVal + "%";
				}
			} else {
				return "0%";
			}
		},
		diffDaysFromCreate(start_date) {
			var a = moment(start_date).startOf("day");
			var b = moment().startOf("day");
			return b.diff(a, "days");
		},
		submitUpdate(e) {
			$(e.target).attr("disabled", "disabled");
			this.$v.$touch();
			if (!this.$v.$invalid) {
				var formData = {
					key_result_id: this.keyResult.id,
					description: this.update_key_result.description,
					value: Number(this.update_key_result.value),
					status: this.update_key_result.degree_of_confidence,
					from_meeting_id: this.data.meetingId,
				};

				axios
					.post(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/${this.keyResult.id}/key-result-logs/store`, formData)
					.then(({ data }) => {
						var sendDataToUpdate = {
							key_result_id: this.keyResult.id,
							last_key_result_log: data.data.last_key_result_log,
							percent: data.data.key_result.percent,
							needs_an_update: data.data.key_result.measurement,
							objective_percent: data.data.objective_percent,
						};

						this.$root.$emit("updateKeyResultMeeting", sendDataToUpdate);

						this.$root.$emit("send_ws_message", {
							entity_id: this.data.meetingId,
							entity_user_id: this.$auth.user().id,
							force_update_ws: true,
							type: "meeting",
							custom_data: {
								type: "kr_update",
								data: {
									...sendDataToUpdate,
									action: "update",
								},
							},
						});
						this.$emit("close_modal");
					})
					.finally(() => {
						setTimeout(() => {
							$(e.target).attr("disabled", false);
						}, 2000);
					});
			} else {
				$(e.target).attr("disabled", false);
			}
		},
	},
};
</script>
