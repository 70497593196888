<template>
	<div class="header-modal">
		<template v-if="loaded">
			<div class="title-box" v-if="!viewHistory">
				<div class="title-top">{{ data.from == "project" ? $t("projects.source") : $t("general.date") }}</div>
				<div class="title">{{ data.from == "project" ? $t("projects.singular") : data.date ? data.date : "-" }}</div>
			</div>
			<div class="title-box" v-else>
				<div class="title">{{ $t("add_task.history_label") }}</div>
			</div>

			<template v-if="!viewHistory">
				<!-- priority button -->
				<div class="filter-dropdown-header icon-dropdown" id="boxDropdownPriority">
					<button class="btn-action-task" id="dropdownPriority" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<icon-flag :class="`icon ${levelOfPriority.color ? levelOfPriority.color : ''}`" />
					</button>
					<div class="dropdown-menu icon-options" aria-labelledby="dropdownPriority">
						<template v-for="option in optionsPriority">
							<div
								class="dropdown-item"
								:class="{ selected: levelOfPriority == option }"
								@click="updateValue('levelOfPriority', option)"
							>
								<div class="icon">
									<icon-flag class="icon" :class="option.color" />
								</div>
								<div class="text">{{ option.name }}</div>
							</div>
						</template>
					</div>
				</div>

				<!-- projects button -->
				<div class="filter-dropdown-header icon-dropdown" id="boxDropdownProjects">
					<button class="btn-action-task" id="dropdownProjects" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<v-popover
							offset="5"
							trigger="hover"
							placement="bottom"
							popoverBaseClass="popover-tbf"
							:delay="{ show: 0, hide: 0 }"
						>
							<div class="icon">
								<div class="inital-letter" v-if="projectSelected">{{ projectSelected.name.charAt(0) }}</div>
								<icon-projects v-else />
							</div>

							<template slot="popover">
								<template v-if="projectSelected">
									<div class="title">{{ $t("projects.singular") }}</div>
									<div class="simple-text" v-html="projectSelected.name"></div>
								</template>
								<div class="simple-text" v-else>{{ $t("add_task.choose_project") }}</div>
							</template>
						</v-popover>
					</button>
					<div class="dropdown-menu icon-options" aria-labelledby="dropdownProjects">
						<template v-for="project in optionsProjects">
							<div class="dropdown-item" @click="updateValue('projectSelected', project)">
								<v-popover
									offset="10"
									trigger="hover"
									placement="bottom"
									popoverBaseClass="popover-tbf"
									:delay="{ show: 0, hide: 0 }"
								>
									<div class="text two-lines" v-html="project.name"></div>

									<template slot="popover">
										<div class="simple-text" v-html="project.name"></div>
									</template>
								</v-popover>
							</div>
						</template>
					</div>
				</div>

				<!-- add tags -->
				<multiselect
					class="hide-tags multiselect_checkbox multiselect-tags"
					v-bind:class="{ populate: selectedTags }"
					v-model="selectedTags"
					:placeholder="$t('add_task.tag')"
					:tag-placeholder="$t('add_task.add_tag')"
					:options="optionsTags"
					open-direction="bottom"
					:allow-empty="true"
					:taggable="true"
					:show-labels="false"
					:multiple="true"
					:close-on-select="false"
					track-by="id"
					label="name"
					@tag="addNewTag"
					@open="closeDropdown"
					@input="updateTags"
				>
					<template slot="placeholder"><icon-tags /></template>
					<template slot="selection" slot-scope="{ values, search, isOpen }">
						<span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
							<icon-tags />
						</span>
					</template>
					<template slot="option" slot-scope="props">
						<div class="checkbox">
							<div class="checkmark"></div>
							<span class="text">{{ props.option.name }}</span>
							<div class="icon-right" @click.stop="openTag(props.option)">
								<icon-horizontal-dots />
							</div>
						</div>
					</template>
					<template slot="noResult">{{ $t("create-user.no-results") }}</template>
					<template slot="noOptions">{{ $t("create-user.empty-list") }}</template>
				</multiselect>

				<div class="separator"></div>

				<!-- timer -->
				<button
					class="btn-action-task timer-task"
					v-if="data.crud == 'edit' && data.responsible && $auth.user().id == data.responsible.id"
					@click="toggleTimerTask"
					:disabled="disabledTimer"
				>
					<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 0, hide: 0 }">
						<template v-if="$parent.timerData.last_status == 'start'">
							<div class="on-timer">
								<icon-stop class="icon red-with-bg stop-icon" />
								<div class="record-icon"></div>
							</div>
						</template>
						<icon-play class="icon primary-blue" v-else />

						<template slot="popover">
							<div class="simple-text" v-if="$parent.timerData.last_status == 'start'">
								{{ $t("add_task.timer_task_stop") }}
							</div>
							<div class="simple-text" v-else>{{ $t("add_task.timer_task_start") }}</div>
						</template>
					</v-popover>
				</button>

				<!-- Duration button -->
				<div
					:class="{ 'small-width': !$parent.taskRights.task_log_crud }"
					class="dropdown-with-input icon-dropdown timers"
					id="boxDropdownDuration"
				>
					<div class="title-top" v-if="duration !== 0">{{ $t("add_task.choose_duration") }}</div>
					<button
						:class="[duration !== 0 ? 'btn-inline-value' : 'btn-action-task']"
						id="dropdownDuration"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<template v-if="duration !== 0">
							<div class="icon">
								<icon-timer />
							</div>
							<span class="text">{{ convertSecondsToHMS(duration) }}</span>
						</template>
						<template v-else>
							<v-popover
								offset="5"
								trigger="hover"
								placement="bottom"
								popoverBaseClass="popover-tbf"
								:delay="{ show: 0, hide: 0 }"
							>
								<div class="icon">
									<icon-timer />
								</div>

								<template slot="popover">
									<div class="simple-text">{{ $t("add_task.choose_duration") }}</div>
								</template>
							</v-popover>
						</template>
					</button>
					<div class="dropdown-menu icon-options" aria-labelledby="dropdownDuration" @click.stop>
						<template v-if="trackTimersViewMode == 'readOnly'">
							<div class="list-time-tracks" v-if="data.timerLogs && data.timerLogs.length">
								<div class="item-track" v-for="timeLog in data.timerLogs">
									<span class="seconds">{{ convertSecondsToHMS(timeLog.seconds) }}</span>
									<span class="date_start">{{ moment(timeLog.start_date_time).format("DD MMM HH:mm") }}</span>
									<div class="actions" v-if="$parent.taskRights.task_log_crud">
										<button class="edit-button" @click.stop="editIntervalTimer(timeLog)"><IconEdit /></button>
										<button class="delete-button" @click.stop="deleteIntervalTimer(timeLog)"><IconTrash /></button>
									</div>
								</div>
							</div>
							<div class="input-dropdown with-flex">
								<label class="total_time">{{ $t("add_task.total_time") }}</label>
								<div class="time-input-container" v-if="$parent.taskRights.can_edit_duration">
									<input
										type="number"
										v-model="timeTrack_hour"
										v-debounce:500="updateValue"
										v-on:keyup.enter="completeInputHour('duration')"
										class="time-input"
										placeholder="HH"
										min="0"
										max="999"
									/>
									<span>:</span>
									<input
										type="number"
										v-model="timeTrack_minutes"
										v-debounce:500="updateValue"
										v-on:keyup.enter="completeInputHour('duration')"
										class="time-input"
										placeholder="MM"
										min="0"
										max="59"
									/>
									<span>:</span>
									<input
										type="number"
										v-model="timeTrack_seconds"
										v-debounce:500="updateValue"
										v-on:keyup.enter="completeInputHour('duration')"
										class="time-input"
										placeholder="SS"
										min="0"
										max="59"
									/>
								</div>
								<div class="time-input-container read-only" v-else>
									{{ convertSecondsToHMS(duration) }}
								</div>
							</div>
						</template>
						<template v-else-if="selectedEditTrackTimer && trackTimersViewMode == 'update'">
							<div class="edit-time-track">
								<div class="header">
									{{ moment(selectedEditTrackTimer.start_date_time).format("DD MMM YYYY HH:mm:ss") }}
								</div>
								<div class="input-dropdown with-flex">
									<label class="total_time">{{ $t("add_task.duration") }}</label>
									<div class="time-input-container">
										<input
											type="number"
											v-model="timeTrack_edit_hour"
											class="time-input"
											placeholder="HH"
											min="0"
											max="999"
										/>
										<span>:</span>
										<input
											type="number"
											v-model="timeTrack_edit_minutes"
											class="time-input"
											placeholder="MM"
											min="0"
											max="59"
										/>
										<span>:</span>
										<input
											type="number"
											v-model="timeTrack_edit_seconds"
											class="time-input"
											placeholder="SS"
											min="0"
											max="59"
										/>
									</div>
								</div>
								<div class="button-actions">
									<button class="btn-tbf white" @click.stop="resetEditTrackTimer">
										<div class="text">{{ $t("general.cancel") }}</div>
									</button>
									<button class="btn-tbf blue" @click.stop="updateIntervalTimer(selectedEditTrackTimer)">
										<div class="loader-spin" v-if="loadingUpdateTimeTrack"></div>
										<div class="text" v-else>{{ $t("general.update") }}</div>
									</button>
								</div>
							</div>
						</template>
					</div>
				</div>

				<!-- Estimation button -->
				<div class="dropdown-with-input icon-dropdown" id="boxDropdownEstimation">
					<template v-if="estimation != ''">
						<div class="title-top">{{ $t("add_task.choose_estimation") }}</div>
						<button
							class="btn-inline-value"
							id="dropdownEstimation"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<div class="icon">
								<icon-hourglass />
							</div>
							<span class="text">{{ $tc("add_task.hours", estimation) }}</span>
						</button>
					</template>
					<template v-else>
						<button
							class="btn-action-task"
							id="dropdownEstimation"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<v-popover
								offset="5"
								trigger="hover"
								placement="bottom"
								popoverBaseClass="popover-tbf"
								:delay="{ show: 0, hide: 0 }"
							>
								<div class="icon">
									<icon-hourglass />
								</div>

								<template slot="popover">
									<div class="simple-text">{{ $t("add_task.choose_estimation") }}</div>
								</template>
							</v-popover>
						</button>
					</template>
					<div class="dropdown-menu icon-options" aria-labelledby="dropdownEstimation">
						<div class="input-dropdown">
							<input
								type="number"
								v-model="estimation"
								v-debounce:500="updateValue"
								v-on:keyup.enter="completeInputHour('estimation')"
								:placeholder="$t('add_task.placeholder_estimation')"
							/>
						</div>
					</div>
				</div>

				<div class="separator"></div>
				<!-- stage button -->
				<div class="filter-dropdown-header dropdown stage" id="boxDropdownStage" v-if="data.pipelineId">
					<button
						class="btn-tbf white"
						id="dropdownStage"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
						:style="`--background-color: ${stage.color_code}`"
						:class="{ light: lightOrDark(stage.color_code) }"
						v-if="stage"
					>
						<span class="text" v-if="!stage.name">{{ $t("general.status") }}</span>
						<span class="text" :style="`--color: ${stage.color_code}`" v-else>{{ stage.name }}</span>
						<div class="icon-filter"><icon-arrow /></div>
					</button>
					<button
						class="btn-tbf white light"
						id="dropdownStage"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
						v-else
					>
						<span class="text">{{ $t("general.status") }}</span>
						<div class="icon-filter"><icon-arrow /></div>
					</button>

					<div class="dropdown-menu" aria-labelledby="dropdownStage">
						<div
							class="dropdown-item"
							@click="updateStage(stageItem)"
							v-bind:class="{ active: stage && stage.id == stageItem.id }"
							v-for="stageItem in stages"
						>
							<div class="checkbox">
								<div class="color-box" :style="`--color: ${stageItem.color_code}`"></div>
								<span class="text">{{ stageItem.name }}</span>
								<div class="checkmark"></div>
							</div>
						</div>
					</div>
				</div>

				<!-- button complete  -->
				<v-popover
					offset="5"
					trigger="hover"
					placement="bottom"
					popoverBaseClass="popover-tbf"
					:delay="{ show: 100, hide: 0 }"
					:disabled="data.done ? true : false"
					v-if="data.from == 'project' ? false : data.crud == 'edit'"
				>
					<button
						id="buttonCompleteSubmit"
						class="btn-action-task done-task"
						:class="{ completed: data.done }"
						@click="checkTask"
					>
						<div class="loader-spin" v-if="loadingCompleteTask"></div>
						<icon-check v-else />
					</button>
					<template slot="popover">
						<div class="simple-text">{{ $t("add_task.finish") }}</div>
					</template>
				</v-popover>
				<div class="message-complete" v-if="data.done" v-html="$t('add_task.finished')"></div>
			</template>
			<div class="actions">
				<div class="watchers">
					<multiselect
						class="hide-tags multiselect_watchers"
						v-model="watchers"
						open-direction="bottom"
						:options="filteredUsers"
						:allow-empty="true"
						:multiple="true"
						:close-on-select="false"
						:show-labels="false"
						:maxHeight="200"
						track-by="id"
						label="name"
						@input="updateValue"
					>
						<template slot="placeholder"><icon-eye class="icon"/></template>

						<template slot="selection" slot-scope="{ values, search, isOpen }">
							<span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
								<icon-eye class="icon" />
								<div class="watchers_bubble">
									{{ watchers.length }}
								</div>
							</span>
						</template>

						<template slot="noResult">{{ $t("departments.no-results") }}</template>
						<template slot="noOptions">{{ $t("departments.empty-list") }}</template>
					</multiselect>
				</div>

				<template v-if="!viewHistory">
					<div class="filter-dropdown-header icon-dropdown">
						<button
							class="btn-action-task"
							id="dropdownFilterActions"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<icon-edit-dots />
						</button>
						<div class="dropdown-menu dropdown-menu-right icon-options" aria-labelledby="dropdownFilterActions">
							<!-- <div class="dropdown-item">
                                <div class="icon">
                                    <icon-clone />
                                </div>
                                <div class="text">{{ $t('general.clone') }}</div>
                            </div> -->
							<div class="dropdown-item" @click="toggleViewHistory" v-if="data.crud == 'edit'">
								<div class="icon">
									<icon-history />
								</div>
								<div class="text">{{ $t("general.view_history") }}</div>
							</div>

							<div
								class="dropdown-item"
								@click="$emit('openModal', { type: 'advanced_settings' })"
								v-if="data.responsible && ($auth.user().id == data.responsible.id || !data.project)"
							>
								<div class="icon">
									<icon-pipeline />
								</div>
								<div class="text">{{ $t("add_task.advanced_settings") }}</div>
							</div>

							<div class="dropdown-item" @click="$emit('openModal', { type: 'delete' })">
								<div class="icon red">
									<icon-trash />
								</div>
								<div class="text">{{ $t("general.delete") }}</div>
							</div>
						</div>
					</div>
				</template>

				<!-- Buton Close -->
				<button class="btn-tbf white only-icon close-btn" @click="viewHistory ? toggleViewHistory() : closeModal()">
					<icon-close class="icon-close" />
				</button>
			</div>
		</template>
		<LoaderTaskHeader v-else />
	</div>
</template>

<script>
import IconClose from "@/components/Icons/Close";
import IconFlag from "@/components/Icons/Flag";
import IconTags from "@/components/Icons/Tags";
import IconTrash from "@/components/Icons/Trash";
import IconEdit from "@/components/Icons/Edit";
import IconEditDots from "@/components/Icons/EditDots";
import IconCheck from "@/components/Icons/CheckLight";
import IconHourglass from "@/components/Icons/Hourglass";
import IconTimer from "@/components/Icons/Timer";
import IconHistory from "@/components/Icons/History";
import IconHorizontalDots from "@/components/Icons/DotsHorizontal";
import IconArrow from "@/components/Icons/Arrow";
import IconPipeline from "@/components/Icons/IconPipeline";
import IconProjects from "@/components/Icons/Projects";
import IconEye from "@/components/Icons/Eye";

import LoaderTaskHeader from "./LoaderTaskHeader";
import IconPlay from "@/components/Icons/Play.vue";
import IconStop from "@/components/Icons/Stop.vue";

export default {
	components: {
		IconClose,
		IconFlag,
		IconTags,
		IconTrash,
		IconEdit,
		IconEditDots,
		IconCheck,
		IconHourglass,
		IconTimer,
		IconHistory,
		IconHorizontalDots,
		IconArrow,
		IconPipeline,
		IconProjects,
		IconEye,
		LoaderTaskHeader,
		IconPlay,
		IconStop,
	},
	props: {
		data: Object,
		optionsProjects: Array,
		optionsTags: Array,
	},
	data() {
		return {
			loaded: false,
			optionsPriority: [
				{ key: "urgent", name: "Urgent", color: "red" },
				{ key: "important", name: "Important", color: "yellow" },
				{ key: "normal", name: "Normal", color: "blue" },
			],
			selectedTags: [],
			levelOfPriority: "",
			duration: 0,
			estimation: "",
			stage: {},
			viewButtonComplete: false,
			loadingCompleteTask: false,
			viewHistory: false,
			projectSelected: "",
			stages: [],
			watchers: [],
			searchUsers: "",
			timeTrack_hour: "",
			timeTrack_minutes: "",
			timeTrack_seconds: "",
			intervalTimer: {},
			disabledTimer: false,
			trackTimersViewMode: "readOnly",
			loadingUpdateTimeTrack: false,
			timeTrack_edit_hour: "",
			timeTrack_edit_minutes: "",
			timeTrack_edit_seconds: "",
		};
	},
	watch: {
		data: function(newVal, oldVal) {
			if (newVal.pipelineId != oldVal.pipelineId) {
				if (newVal.pipelineId) {
					// this.pipelineId = newVal.pipelineId;
					this.getStages("from_watch");
				} else {
					this.stage = {};
				}
			}

			if (newVal.timer != oldVal.timer) {
				if (newVal.timer == "start") {
					this.intervalTimer = setInterval(() => {
						this.duration += 1;
					}, 1000);
				} else {
					clearInterval(this.intervalTimer);
				}
			}

			if (newVal.duration != oldVal.duration) {
				this.duration = newVal.duration;
			}
		},
	},
	computed: {
		filteredUsers() {
			return getByKeywordFilter(this.$parent.optionsUsers, this.searchUsers);
		},
	},
	async mounted() {
		this.levelOfPriority = this.data.levelOfPriority ? this.optionsPriority.find((el) => el.key == this.data.levelOfPriority) : "";
		this.projectSelected = this.data.project;
		this.selectedTags = this.data.selectedTags;
		this.duration = this.data.duration;
		this.estimation = this.data.estimation;
		this.watchers = this.data.watchers;

		if (this.data.fromKanban) {
			this.projectSelected = this.optionsProjects.find((el) => el.id == this.data.projectId);
		}

		this.viewButtonComplete = this.data.from == "project" ? false : true;

		await this.generateTimeTrackInput();

		if (this.$parent.timerData.last_status == "start") {
			this.intervalTimer = setInterval(() => {
				this.duration += 1;
			}, 1000);
		}

		// Get stages for the pipeline selected
		if (this.data.pipelineId) {
			await this.getStages("pipeline");
		} else {
			this.loaded = true;
		}
	},
	methods: {
		addNewTag(nameNewTag) {
			axios
				.post(`/tags/store`, {
					name: nameNewTag,
					color_code: "#" + ((Math.random() * 0xffffff) << 0).toString(16),
				})
				.then(({ data }) => {
					var newTag = {
						name: data.data.name,
						color_code: data.data.color_code,
						id: data.data.id,
						light: this.lightOrDark(data.data.color_code),
					};

					this.optionsTags.push(newTag);
					this.selectedTags.push(newTag);
					// this.$parent.taskData.selectedTags.push(newTag);
					// this.getFilters()
				});
		},
		lightOrDark(color) {
			if (!color) {
				return;
			}
			var r, g, b, hsp;

			color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));
			r = color >> 16;
			g = (color >> 8) & 255;
			b = color & 255;

			// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
			hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

			// Using the HSP value, determine whether the color is light or dark
			if (hsp > 190) {
				return true;
			} else {
				return false;
			}
		},
		closeDropdown() {
			if ($("#boxDropdownPriority").hasClass("show")) {
				$("#dropdownPriority").dropdown("toggle");
			}
			if ($("#boxDropdownAvatars").hasClass("show")) {
				$("#dropdownAvatars").dropdown("toggle");
			}
			if ($("#boxDropdownProjects").hasClass("show")) {
				$("#dropdownProjects").dropdown("toggle");
			}
			if ($("#boxDropdownDuration").hasClass("show")) {
				$("#dropdownDuration").dropdown("toggle");
			}
			if ($("#boxDropdownEstimation").hasClass("show")) {
				$("#dropdownEstimation").dropdown("toggle");
			}
		},
		openTag(tag) {
			this.$emit("openModal", { type: "edit_tag", data: tag });
			// this.selectedTag = tag
			// this.openSubModal('edit_tag')
		},
		completeInputHour(type) {
			setTimeout(() => {
				$(`#dropdown${type.charAt(0).toUpperCase() + type.slice(1)}`).dropdown("toggle");
			}, 0);
		},
		updateStage(stage) {
			this.stage = stage;
			this.$parent.stage_id = stage.id;
			this.$root.$emit("refreshProjectPage");
		},
		updateTags(data) {
			var objectData = {
				name: this.$parent.taskData.name,
				tags: data.map((el) => el.id),
			};

			axios.post(`/tasks/${this.$parent.parentTaskId}/update`, objectData).then(() => {});

			this.updateValue(data);
		},
		checkTask() {
			this.loadingCompleteTask = true;
			var isRecurrence = this.data.frequency != "one_time";
			var deadlineDate = Object.keys(this.data.deadlineDate).length
				? moment(this.data.deadlineDate.selectedDate, "D/M/YYYY").format("YYYY-MM-DD")
				: "";
			var startDate = Object.keys(this.data.dateOfStart).length
				? moment(this.data.dateOfStart.selectedDate, "D/M/YYYY").format("YYYY-MM-DD")
				: "";
			var generateDate = isRecurrence ? (this.data.crud == "edit" && this.data.oldDate ? this.data.date : startDate) : deadlineDate;
			var newDate = generateDate ? generateDate : moment().format("YYYY-MM-DD");

			var formCheck = {
				date: newDate,
				done: this.data.done ? 0 : 1,
			};

			// Parameter to handle tasks from projects
			if (this.data.hasOwnProperty("from_meeting_id")) {
				formCheck.from_meeting_id = this.data.from_meeting_id;
				formCheck.from_meeting_step = this.data.from_meeting_step;
				formCheck.force_update_ws = true;
			} else {
				formCheck.from_calendar = 1;
			}

			axios.post(`/tasks/${this.$parent.parentTaskId}/check`, formCheck).then(() => {
				this.refreshCalendar();
			});
		},
		toggleViewHistory() {
			this.viewHistory = !this.viewHistory;
			this.$emit("changeView", { show: this.viewHistory });
		},
		getStages(projectId) {
			var paramsCall = {
				pipeline_stages: true,
			};

			if (projectId == "project") {
				paramsCall.project_id = this.projectSelected.id;
			} else {
				paramsCall.pipeline_id = this.data.pipelineId;
			}

			axios
				.get(`instances/${this.$auth.user().instance.id}/filter`, {
					params: paramsCall,
				})
				.then(({ data }) => {
					if (!data.data.pipeline_stages) {
						this.$parent.pipeline_id = "";
						this.$parent.stage_id = "";
					} else {
						this.stages = data.data.pipeline_stages.stages;
						this.$parent.pipeline_id = data.data.pipeline_stages.id;
						this.$parent.stage_id = this.stages[0].id;

						if (["from_watch", "project"].includes(projectId)) {
							this.stage = this.stages[0];
						} else {
							this.stage = this.stages.find((el) => el.id == this.data.stageId);
						}
					}
				})
				.finally(() => {
					this.loaded = true;
				});
		},
		refreshCalendar() {
			this.$root.$emit("refreshSplitView");
			this.$root.$emit("refresh_ToDo_List");
			this.$parent.$emit("close_modal");
		},
		closeModal() {
			this.$parent.$emit("close_modal");
		},
		updateValue(type = false, value = false) {
			switch (type) {
				case "levelOfPriority":
					this.levelOfPriority = this.levelOfPriority == value ? "" : value;
					break;
				case "projectSelected":
					this.projectSelected = this.projectSelected == value ? "" : value;

					if (this.projectSelected == "") {
						this.$parent.stage_id = "";
						this.$parent.pipeline_id = "";
					} else {
						this.getStages("project");
					}
					break;
				default:
					break;
			}

			var hoursInSeconds = this.timeTrack_hour ? parseInt(this.timeTrack_hour) * 3600 : 0;
			var minutesInSeconds = this.timeTrack_minutes ? parseInt(this.timeTrack_minutes) * 60 : 0;
			var seconds = this.timeTrack_seconds ? parseInt(this.timeTrack_seconds) : 0;
			var calculateDurationInSeconds = hoursInSeconds + minutesInSeconds + seconds;
			this.duration = calculateDurationInSeconds;

			//Generate object to update parent data
			var objData = {
				levelOfPriority: this.levelOfPriority ? this.levelOfPriority.key : "",
				project: this.projectSelected,
				selectedTags: this.selectedTags,
				duration: calculateDurationInSeconds,
				estimation: this.estimation,
				watchers: this.watchers,
			};

			this.$emit("updateData", objData);
		},
		chooseWatchers(userSelected) {
			if (this.watchers.find((el) => el.id == userSelected.id)) {
				var indexUser = this.watchers.findIndex((el) => el.id == userSelected.id);
				this.watchers.splice(indexUser, 1);
			} else {
				this.watchers.push(userSelected);
			}

			// if(this.taskData.responsible && this.taskData.responsible.id == userSelected.id) {
			//     this.taskData.responsible = '';
			// } else {
			//     this.taskData.responsible = userSelected;
			// }
		},
		toggleTimerTask() {
			this.disabledTimer = true;
			var newStatus = !this.$parent.timerData.last_status || this.$parent.timerData.last_status == "stop" ? "start" : "stop";
			var timerData = {
				date: this.data.date,
				status: newStatus,
			};
			axios
				.post(`/${this.$parent.parentTaskId}/timer-store`, timerData)
				.then(({ data }) => {
					this.$parent.timerData.last_status = newStatus;
					this.$parent.timerData.logs = data.data.task_timer_logs;
					this.duration = data.data.duration;
					this.generateTimeTrackInput();

					this.$root.$emit("refreshTimer");
				})
				.catch(({ response }) => {
					if (response.data.errors && response.data.errors.force_start) {
						this.$parent.openSubModal("info_timer_task", response.data.errors.force_start[0]);
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.disabledTimer = false;
					}, 1000);
				});
		},
		convertSecondsToHMS(seconds) {
			const hours = Math.floor(seconds / 3600);
			const minutes = Math.floor((seconds % 3600) / 60);
			const secondsRemaining = seconds % 60;
			const formattedHours = hours.toString().padStart(2, "0");
			const formattedMinutes = minutes.toString().padStart(2, "0");
			const formattedSeconds = secondsRemaining.toString().padStart(2, "0");
			return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
		},
		generateTimeTrackInput() {
			const hours = Math.floor(this.duration / 3600);
			const minutes = Math.floor((this.duration % 3600) / 60);
			const secondsRemaining = this.duration % 60;

			this.timeTrack_hour = hours !== 0 ? hours : "";
			this.timeTrack_minutes = minutes !== 0 ? minutes : "";
			this.timeTrack_seconds = secondsRemaining !== 0 ? secondsRemaining : "";
		},
		resetEditTrackTimer() {
			this.trackTimersViewMode = "readOnly";
			this.selectedEditTrackTimer = null;

			this.timeTrack_edit_hour = "";
			this.timeTrack_edit_minutes = "";
			this.timeTrack_edit_seconds = "";
		},
		deleteIntervalTimer(itemTimer) {
			axios
				.delete(`${this.data.logId}/task-timers`, {
					params: { task_timers_ids: [itemTimer.start_timer_id, itemTimer.end_timer_id] },
				})
				.then(({ data }) => {
					this.duration = data.data.duration;
					this.$parent.timerData.logs = data.data.task_timer_logs;

					this.$root.$emit("refreshTimer");
				});
		},
		editIntervalTimer(itemTimer) {
			this.selectedEditTrackTimer = itemTimer;
			this.trackTimersViewMode = "update";

			const hours = Math.floor(itemTimer.seconds / 3600);
			const minutes = Math.floor((itemTimer.seconds % 3600) / 60);
			const secondsRemaining = itemTimer.seconds % 60;

			this.timeTrack_edit_hour = hours !== 0 ? hours : "";
			this.timeTrack_edit_minutes = minutes !== 0 ? minutes : "";
			this.timeTrack_edit_seconds = secondsRemaining !== 0 ? secondsRemaining : "";
		},
		updateIntervalTimer(itemTimer) {
			this.loadingUpdateTimeTrack = true;

			var hoursInSeconds = this.timeTrack_edit_hour ? parseInt(this.timeTrack_edit_hour) * 3600 : 0;
			var minutesInSeconds = this.timeTrack_edit_minutes ? parseInt(this.timeTrack_edit_minutes) * 60 : 0;
			var seconds = this.timeTrack_edit_seconds ? parseInt(this.timeTrack_edit_seconds) : 0;
			var calculateDurationInSeconds = hoursInSeconds + minutesInSeconds + seconds;

			axios
				.patch(`task-timers/${itemTimer.end_timer_id}`, {
					seconds: calculateDurationInSeconds,
					start_timer_id: itemTimer.start_timer_id,
				})
				.then(({ data }) => {
					this.duration = data.data.duration;
					this.$parent.timerData.logs = data.data.task_timer_logs;

					this.$root.$emit("refreshTimer");
					this.loadingUpdateTimeTrack = false;
					this.resetEditTrackTimer();
				});
		},
	},
};

function getByKeywordFilter(list, keyword) {
	const search = keyword.trim();

	if (!search.length) return list;
	return list.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
}
</script>
